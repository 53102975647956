import React from "react";
import { Box, HStack, ScrollView, VStack } from "native-base";
import {
  ParticipantProfileWithPropertiesDto,
  PostReturnDto,
} from "../../services/api-service-sub-services/studies-api-service";
import { PostContainer } from "./post.container";
import { PostComponent } from "./components/post/post.component";
import { MaterialIcons } from "@expo/vector-icons";
import { CreateNewCommentPlaceholder } from "./components/comments/create-new-comment-placeholder.component";
import { TouchableOpacity } from "react-native";
import { OpenCreateNewCommentSheetParams } from "./hooks/use-open-comment-bottom-sheet.hook";
import { CircularButton } from "../core/components/circular-button.component";

const Post = PostContainer(PostComponent);

export type SinglePostScreenProps = {
  post?: PostReturnDto;
  onBackButtonPress: () => void;
  highlightedCommentId?: string;
  profile?: ParticipantProfileWithPropertiesDto;
  openCreateNewCommentSheet: (params: OpenCreateNewCommentSheetParams) => void;
};

export const SinglePostScreen = (props: SinglePostScreenProps) => {
  return (
    <VStack variant="scrollable-screen" safeAreaTop>
      <HStack p={3} alignItems="center" justifyContent="space-between">
        <CircularButton
          onPress={props.onBackButtonPress}
          icon={<MaterialIcons name="arrow-back" size={22} />}
          shadow={1}
        />
      </HStack>
      <ScrollView
        backgroundColor="xLightGrey"
        paddingTop={3}
        px={3}
        automaticallyAdjustKeyboardInsets
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
      >
        {props.post && (
          <Post
            post={props.post}
            isCommentSectionVisibleByDefault={true}
            highlightedCommentId={props.highlightedCommentId}
          />
        )}
        <Box height={100} />
      </ScrollView>

      <Box marginX={5} marginBottom={10} shadow={3} marginTop={2}>
        <TouchableOpacity
          onPress={() => {
            props.openCreateNewCommentSheet({
              profile: props.post?.profile,
              content: props.post?.content,
            });
          }}
        >
          <CreateNewCommentPlaceholder profile={props.profile} />
        </TouchableOpacity>
      </Box>
    </VStack>
  );
};
