import { Button, Heading, Text, VStack } from "native-base";
import React from "react";
import { ModalComponentProp } from "react-native-modalfy";
import { ModalStackParams } from "./modal-stack";
import { Dimensions, Platform } from "react-native";

type Props = ModalComponentProp<ModalStackParams, void, "ErrorModal">;
export const ErrorModal = (props: Props) => {
  const { width } = Dimensions.get("window");
  return (
    <VStack
      variant="screen"
      borderRadius="8"
      px="5"
      py="6"
      space="5"
      width={width * 0.8}
      maxWidth={Platform.OS === "web" ? "625px" : width}
    >
      <VStack space="1">
        <Heading
          textAlign="left"
          variant="heading"
          textTransform="uppercase"
          fontSize="22"
        >
          Something&apos;s Not Right
        </Heading>
        <Text variant="small" textAlign="left" letterSpacing="0.5">
          {props.modal.params?.message}
        </Text>
      </VStack>
      {props.modal.params?.onContinue && (
        <Button
          variant="primary"
          height="10"
          onPress={() => props.modal.params?.onContinue?.()}
        >
          Continue
        </Button>
      )}
    </VStack>
  );
};
