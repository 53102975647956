import React from "react";
import { SettingsScreenProps } from "./settings.screen";
import { useTrackScreenFocus } from "../core/hooks/use-track-screen-focus";
import { events } from "../../constants/analytics.constants";
import { useLogout } from "../core/utils/hooks/use-logout";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { Routes } from "../../navigation/routes";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { useAppSelector } from "../../store/store";
import { selectUserProfile } from "../../store/user.slice";
import { Platform } from "react-native";

type SettingsContainerProps = {};

export const SettingsContainer = (Screen: React.FC<SettingsScreenProps>) => {
  return function _(props: SettingsContainerProps) {
    useTrackScreenFocus(events.userOpenedSettingsScreen);
    const { logout } = useLogout();
    const navigation = useNavigation<NavigationProp<AppStackParamList>>();
    const userProfile = useAppSelector(selectUserProfile);

    return (
      <Screen
        onLogoutButtonPress={logout}
        profile={userProfile!}
        onReportProblemButtonPress={() => {
          navigation.navigate(Routes.APP_STACK__IN_APP_BROWSER, {
            url: `https://spark-social.com/feedback?Participant%20Code=${userProfile?.participantCode?.toUpperCase()}&Platform=${
              Platform.OS === "android" ? "Android" : "iOS"
            }`,
            title: "",
          });
        }}
      />
    );
  };
};
