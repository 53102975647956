import { getImageUrlFromCdn } from "../utils/image-handler";
import React, { ReactElement } from "react";

interface OptimizedCdnImageProps {
  uri: string;
  height?: number;
  width?: number;
  fit?: "cover" | "contain" | "fill" | "inside" | "outside";
  render: (source: { uri: string }) => ReactElement<any>;
}

export const OptimizedCdnImage = React.memo((props: OptimizedCdnImageProps) => {
  const imageUrl = getImageUrlFromCdn({
    originalUrl: props.uri,
    height: props.height,
    width: props.width,
    fit: props.fit,
  });

  return props.render({ uri: imageUrl });
});
