"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileAsyncTransport = void 0;
let EXPOqueue = [];
let EXPOelaborate = false;
const EXPOFSreadwrite = async () => {
    EXPOelaborate = true;
    const item = EXPOqueue[0];
    const prevFile = await item.FS.readAsStringAsync(item.file);
    const newMsg = prevFile + item.msg;
    await item.FS.writeAsStringAsync(item.file, newMsg);
    EXPOelaborate = false;
    EXPOqueue.shift();
    if (EXPOqueue.length > 0) {
        await EXPOFSreadwrite();
    }
};
const EXPOcheckqueue = async (FS, file, msg) => {
    EXPOqueue.push({ FS, file, msg });
    if (!EXPOelaborate) {
        await EXPOFSreadwrite();
    }
};
const EXPOFSappend = async (FS, file, msg) => {
    try {
        const fileInfo = await FS.getInfoAsync(file);
        if (!fileInfo.exists) {
            await FS.writeAsStringAsync(file, msg);
            return true;
        }
        else {
            await EXPOcheckqueue(FS, file, msg);
            return true;
        }
    }
    catch (error) {
        console.error(error);
        return false;
    }
};
const RNFSappend = async (FS, file, msg) => {
    try {
        await FS.appendFile(file, msg, "utf8");
        return true;
    }
    catch (error) {
        console.error(error);
        return false;
    }
};
const dateReplacer = (filename, type) => {
    let today = new Date();
    let d = today.getDate();
    let m = today.getMonth() + 1;
    let y = today.getFullYear();
    switch (type) {
        case "eu":
            return filename.replace("{date-today}", `${d}-${m}-${y}`);
        case "us":
            return filename.replace("{date-today}", `${m}-${d}-${y}`);
        case "iso":
            return filename.replace("{date-today}", `${y}-${m}-${d}`);
        default:
            return filename.replace("{date-today}", `${d}-${m}-${y}`);
    }
};
const fileAsyncTransport = (props) => {
    var _a, _b, _c, _d;
    if (!props)
        return false;
    let WRITE;
    let fileName = "log";
    let filePath;
    if (!((_a = props === null || props === void 0 ? void 0 : props.options) === null || _a === void 0 ? void 0 : _a.FS)) {
        throw Error(`react-native-logs: fileAsyncTransport - No FileSystem instance provided`);
    }
    if (props.options.FS.DocumentDirectoryPath && props.options.FS.appendFile) {
        WRITE = RNFSappend;
        filePath = props.options.FS.DocumentDirectoryPath;
    }
    else if (props.options.FS["documentDirectory"] &&
        props.options.FS["writeAsStringAsync"] &&
        props.options.FS["readAsStringAsync"] &&
        props.options.FS["getInfoAsync"]) {
        WRITE = EXPOFSappend;
        filePath = props.options.FS.documentDirectory;
    }
    else {
        throw Error(`react-native-logs: fileAsyncTransport - FileSystem not supported`);
    }
    if ((_b = props === null || props === void 0 ? void 0 : props.options) === null || _b === void 0 ? void 0 : _b.fileName) {
        fileName = props.options.fileName;
        fileName = dateReplacer(fileName, (_c = props.options) === null || _c === void 0 ? void 0 : _c.fileNameDateType);
    }
    if ((_d = props === null || props === void 0 ? void 0 : props.options) === null || _d === void 0 ? void 0 : _d.filePath)
        filePath = props.options.filePath;
    let output = `${props === null || props === void 0 ? void 0 : props.msg}\n`;
    var path = filePath + "/" + fileName;
    WRITE(props.options.FS, path, output);
};
exports.fileAsyncTransport = fileAsyncTransport;
