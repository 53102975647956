import getVideoId from "get-video-id";

export const getEmbeddedVideoSource = (url: string) => {
  const video = getVideoId(url);
  
  if (video.id && video.service === "youtube") {
    return `https://www.youtube.com/embed/${video.id}`;
  }
  if (video.id && video.service === "vimeo") {
    return `https://player.vimeo.com/video/${video.id}`;
  }
  return "";
};
