import React from "react";
import { Box, VStack } from "native-base";
import { TaggedProfilesEntity } from "../../../../services/api-service-sub-services/studies-api-service";
import { extractURLs } from "../../../core/utils/url.util";
import { LinkPreviewComponent } from "../common/link-preview.component";
import { TextContentRenderer } from "../common/text-content-renderer.component";

type CommentContentProps = {
  content: string;
  mentionedProfiles?: TaggedProfilesEntity[];
  onMentionedProfilePress: (profileId: string) => void;
  truncate?: boolean;
};

export const CommentContent = (props: CommentContentProps) => {
  const urls = extractURLs(props.content);

  return (
    <VStack space="3">
      <TextContentRenderer
        content={props.content}
        onMentionedProfilePress={props.onMentionedProfilePress}
        mentionedProfiles={props.mentionedProfiles}
        truncate={props.truncate}
      />

      {/* display any URLs in the comment */}
      {urls.length > 0 && !props.truncate && (
        <Box mt="2" borderRadius="xl" overflow="hidden">
          <LinkPreviewComponent url={urls[0]} />
        </Box>
      )}
    </VStack>
  );
};
