import { useAppDispatch, useAppSelector } from "../../../store/store";
import { selectTreatmentArmReactivePopups } from "../../../store/app.slice";
import { useModal } from "react-native-modalfy";
import { ModalStackParams } from "../modals/modal-stack";
import { useExtensionsControllerInvokeLambdaFunctionMutation } from "../../../services/api-service-sub-services/extensions-api-service";
import { getModalInstructions, getModalType } from "../utils/get-modal-type";
import { analyticsSlice } from "../../../store/analytics.slice";
import { useStudiesControllerUpdateProfileMutation } from "../../../services/api-service-sub-services/studies-api-service";
import {
  selectUserProfile,
  selectUserStudyId,
} from "../../../store/user.slice";

type ReactPopupsAction =
  | "when-user-creates-post"
  | "when-user-creates-comment"
  | "when-user-engages-post"
  | "when-user-follows-bot"
  | "when-user-unfollows-bot"
  | "when-user-opens-a-link"
  | "before-user-creates-post";

/**
 * Get the lambda function name from the condition key
 * @param {string} conditionKey
 */
const getLambdaFunctionName = (conditionKey: string): string => {
  let lambdaName = "";
  if (conditionKey.startsWith("if-satisfies-lambda")) {
    lambdaName = conditionKey.replace("if-satisfies-lambda-", "");
  } else {
    lambdaName = conditionKey.replace("if-does-not-satisfies-lambda-", "");
  }
  return lambdaName;
};

const isConditionKeyLambda = (conditionKey: string): boolean => {
  return (
    conditionKey.startsWith("if-satisfies-lambda") ||
    conditionKey.startsWith("if-does-not-satisfies-lambda")
  );
};

export const useReactivePopupsSideEffect = (): {
  triggerPopupSideEffect: (action: ReactPopupsAction) => Promise<void>;
} => {
  const reactivePopups = useAppSelector(selectTreatmentArmReactivePopups);
  const [invokeLambdaFunction] =
    useExtensionsControllerInvokeLambdaFunctionMutation();
  const { openModal } = useModal<ModalStackParams>();
  const dispatch = useAppDispatch();
  const [updateBotProfilePrompts] = useStudiesControllerUpdateProfileMutation();
  const profile = useAppSelector(selectUserProfile);
  const studyId = useAppSelector(selectUserStudyId);

  const triggerPopupSideEffect = async (action: ReactPopupsAction) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // holds the text replacements for the modal
        let textReplacementSet = {};

        // let's check if the modal has opened once. If it has opened once
        // then we don't automatically resolve the promise
        let modalOpenedOnce = false;

        for (const reactivePopup of reactivePopups) {
          const meta = reactivePopup.meta;

          // @ts-ignore
          if (meta?.triggers) {
            // @ts-ignore
            const { triggers } = meta;

            for (const triggerKey in triggers) {
              if (triggerKey === action) {
                // get the trigger conditions
                // @ts-ignore
                const { conditions } = triggers[triggerKey];

                // iterate over each condition
                let matchedAllConditions = true;

                for await (const conditionKey of Object.keys(conditions)) {
                  // check for lambda triggers
                  if (isConditionKeyLambda(conditionKey)) {
                    try {
                      const lambdaName = getLambdaFunctionName(conditionKey);
                      // invoke the lambda function
                      const response = await invokeLambdaFunction({
                        provider: "aws-lambda",
                        invokeLambdaExtensionDto: {
                          name: "popup-trigger-" + lambdaName,
                        },
                      }).unwrap();

                      const { isMatched, textReplacementSet: replacementSet } =
                        response.result;

                      if (conditionKey.startsWith("if-satisfies-lambda")) {
                        if (isMatched) {
                          textReplacementSet = Object.assign(
                            textReplacementSet,
                            replacementSet
                          );
                        } else {
                          matchedAllConditions = false;
                        }
                      } else {
                        if (!isMatched) {
                          textReplacementSet = Object.assign(
                            textReplacementSet,
                            replacementSet
                          );
                        } else {
                          matchedAllConditions = false;
                        }
                      }
                    } catch (e) {
                      matchedAllConditions = false;
                    }
                  } else {
                    // force false for any unknown conditions
                    matchedAllConditions = false;
                  }
                }

                ////////////////////////////////////////////////////////////////
                /// Display the popup
                ////////////////////////////////////////////////////////////////

                // matched all conditions for the trigger -> display the modal
                if (matchedAllConditions) {
                  modalOpenedOnce = true;
                  if (
                    reactivePopup.promptOverrides &&
                    reactivePopup.promptOverrides.length > 0
                  ) {
                    await updateBotProfilePrompts({
                      id: studyId as string,
                      profileId: profile!._id,
                      updateProfileDto: {
                        promptOverrides: reactivePopup.promptOverrides,
                      },
                    }).unwrap();
                  }

                  openModal("InstructionsModal", {
                    type: getModalType(reactivePopup),
                    instructions: getModalInstructions(
                      reactivePopup,
                      textReplacementSet
                    ),
                    continueActionType: reactivePopup.popup?.continueActionType,
                    continueActionMeta: reactivePopup.popup?.continueActionMeta,
                    onClose: () => {
                      dispatch(
                        analyticsSlice.actions.popupTriggered(
                          reactivePopup.popup._id
                        )
                      );
                      resolve();
                    },
                  });
                }
              }
            }
          }
        }

        // let the modal resolve the promise on its own (by closing), but
        // if no modal was opened, resolve immediately
        if (!modalOpenedOnce) {
          resolve();
        }
      } catch (e) {
        resolve();
      }
    });
  };

  return {
    triggerPopupSideEffect,
  };
};
