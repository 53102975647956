import React, { useMemo } from "react";
import WebView from "react-native-webview";
import { AspectRatio, View } from "native-base";
import { getEmbeddedVideoSource } from "../../utils/video";
import { Platform } from "react-native";

export type PostContentVideoProps = {
  url: string;
};

export const PostContentVideo = (props: PostContentVideoProps): JSX.Element => {
  /** Returns a correct embedding code for URLs that match YT and Vimeo links */
  const embeddedVideoSource = useMemo(() => {
    return getEmbeddedVideoSource(props.url);
  }, [props.url]);

  return Platform.OS === "web" ? (
    <View style={{ aspectRatio: 16 / 9 }}>
      <iframe src={embeddedVideoSource} width="100%" height="100%" />
    </View>
  ) : (
    <AspectRatio ratio={16 / 9}>
      <WebView source={{ uri: embeddedVideoSource }} useWebView2 />
    </AspectRatio>
  );
};
