import ActionSheet, {
  SheetProps,
  useSheetRef,
} from "react-native-actions-sheet";
import React, { useState } from "react";
import { useFeatureAvailability } from "../hooks/use-feature-availability";
import { Box, HStack, Text, TextArea, useTheme, VStack } from "native-base";
import { CommentThreadItemHeader } from "../../posts/components/comments/comment-thread-item-header.component";
import { useGetDisplayName } from "../../posts/utils/names";
import { CommentContent } from "../../posts/components/comments/comment-content.component";
import { ProfileAvatar } from "../components/profile-avatar";
import { useAppSelector } from "../../../store/store";
import { selectUserProfile } from "../../../store/user.slice";
import { MaterialIcons } from "@expo/vector-icons";
import { useMentions } from "react-native-controlled-mentions";
import { MentionSuggestionsList } from "../../posts/components/common/mentions-suggestion-list";
import { ParticipantProfileValue } from "../../../services/api-service-sub-services/users-api-service";
import { Keyboard } from "react-native";
import { CircularButton } from "../components/circular-button.component";

export type CreateNewCommentSheetProps = SheetProps<"create-new-comment-sheet">;

export const CreateNewCommentSheet = (props: CreateNewCommentSheetProps) => {
  const theme = useTheme();
  const isBotTaggingEnabled = useFeatureAvailability("bot-tagging");
  const profile = useAppSelector(selectUserProfile);
  const opContent = props.payload?.content;
  const opProfile = props.payload?.profile;

  const getDisplayName = useGetDisplayName();
  const displayName = getDisplayName({
    participantProfileValues:
      // TODO remove cast
      (opProfile?.properties as ParticipantProfileValue[]) || [],
  });

  const actionSheetRef = useSheetRef(props.sheetId);
  const [content, setContent] = useState<string>("");

  const handleContentChange = (text: string) => {
    setContent(text);
  };

  const { textInputProps, triggers } = useMentions({
    value: content,
    onChange: handleContentChange,
    triggersConfig: {
      mention: {
        trigger: "@",
        textStyle: { fontWeight: "bold" },
      },
    },
  });

  const hasSourceContent = opContent && opProfile;

  return (
    <ActionSheet id={props.sheetId}>
      <VStack backgroundColor="white" borderRadius="3xl" overflow="hidden">
        <HStack
          backgroundColor="white"
          borderTopRadius="xl"
          overflow="hidden"
          justifyContent="space-between"
          p={3}
        >
          <CircularButton
            onPress={() => {
              setContent("");
              Keyboard.dismiss();
              props.payload?.onCancelButtonPress?.(content);
              actionSheetRef.current?.hide();
            }}
            icon={<MaterialIcons name="close" size={22} />}
          />

          {!!props.payload?.onCommentButtonPress && (
            <CircularButton
              onPress={async () => {
                setContent("");
                Keyboard.dismiss();
                actionSheetRef.current?.hide();
                await props.payload?.onCommentButtonPress(content);
              }}
              icon={
                <MaterialIcons
                  name="send"
                  color={theme.colors.primary as unknown as string}
                  size={22}
                />
              }
            />
          )}
        </HStack>

        <Box padding={3} borderTopRadius="3xl" backgroundColor="xLightGrey">
          {hasSourceContent && (
            <VStack space={1}>
              <Box>
                <Text fontSize="14" fontWeight="bold">
                  Replying to {displayName}
                </Text>
              </Box>
              <HStack
                borderRadius="xl"
                backgroundColor="white"
                padding={3}
                space={2}
              >
                <ProfileAvatar profile={opProfile} length="8" />
                <VStack flex={1}>
                  <CommentThreadItemHeader
                    hideAvatar={true}
                    date={new Date()}
                    profile={opProfile}
                  />
                  <CommentContent
                    truncate
                    content={opContent}
                    onMentionedProfilePress={() => {}}
                  />
                </VStack>
              </HStack>
            </VStack>
          )}

          {hasSourceContent && (
            <Box height="16px" width={0.5} backgroundColor="medGrey" left={6} />
          )}

          <HStack space={2}>
            <ProfileAvatar profile={profile} length="12" />
            <Box flex={1}>
              <TextArea
                autoCompleteType={undefined}
                autoFocus={true}
                variant="primary"
                backgroundColor="white"
                borderRadius="xl"
                p="3"
                w="full"
                h="auto"
                fontSize="16"
                multiline={true}
                placeholder="Write something..."
                {...(isBotTaggingEnabled
                  ? textInputProps
                  : {
                      value: content,
                      onChangeText: handleContentChange,
                    })}
              />
            </Box>
          </HStack>
          <Box mt={3}>
            <MentionSuggestionsList {...triggers.mention} />
          </Box>
        </Box>
      </VStack>
    </ActionSheet>
  );
};
