import React from "react";
import { Column, HStack, Row, Text, VStack } from "native-base";
import { ProfileAvatar } from "./profile-avatar";
import { ParticipantProfileWithPropertiesDto } from "../../../services/api-service-sub-services/users-api-service";
import { useProfileValues } from "../../profile/hooks/use-profile-values";
import { LocationIcon } from "../icons/location-icon";
import { PoliticalAffiliationIcon } from "../icons/politication-affiliation-icon";
import { GenderIcon } from "../icons/gender-icon";
import { useAppSelector } from "../../../store/store";
import { selectExperienceIsConnectionsEnabled } from "../../../store/app.slice";

type ProfileMetadataProps = {
  profile?: ParticipantProfileWithPropertiesDto;
  postCount: number;
};
export const ProfileMetadata = (props: ProfileMetadataProps) => {
  const profileValues = useProfileValues(props.profile?.properties);

  const isConnectionsEnabled = useAppSelector(
    selectExperienceIsConnectionsEnabled
  );

  return (
    <Column
      px="5"
      bg="white"
      pt="5"
      pb="3"
      space={3}
      borderBottomRadius="3xl"
      overflow="hidden"
    >
      <Row width="100%" justifyContent="center">
        <ProfileAvatar profile={props.profile} length={24} />
      </Row>

      <VStack width="100%" alignItems="center" justifyContent="center">
        <Text variant="heading5" bold>
          {profileValues.displayName}
        </Text>

        <Row space="2" alignItems="center" justifyContent="center">
          {profileValues.location && (
            <ProfileMetaChip
              icon={<LocationIcon />}
              label={profileValues.location}
            />
          )}
          {profileValues.politicalAffiliation && (
            <ProfileMetaChip
              icon={<PoliticalAffiliationIcon />}
              label={profileValues.politicalAffiliation}
            />
          )}
          {profileValues.gender && (
            <ProfileMetaChip
              icon={<GenderIcon />}
              label={profileValues.gender}
            />
          )}
        </Row>
      </VStack>

      <HStack alignItems="center" justifyContent="center" px={3} space={5}>
        <ProfileStatsBox
          value={props.postCount}
          label={props.postCount !== 1 ? "Posts" : "Post"}
        />
        {isConnectionsEnabled && (
          <>
            <ProfileStatsBox
              value={props.profile?.following || 0}
              label="Following"
            />
            <ProfileStatsBox
              value={props.profile?.followers || 0}
              label="Followers"
            />
          </>
        )}
      </HStack>

      {profileValues.bioDescription && (
        <Text variant="small" mb="3">
          {profileValues.bioDescription}
        </Text>
      )}
    </Column>
  );
};

const ProfileMetaChip = (props: { icon: React.ReactNode; label: string }) => {
  return (
    <HStack
      space="2"
      alignItems="center"
      backgroundColor="xLightGrey"
      p={1}
      px={2}
      borderRadius="xl"
    >
      {props.icon}
      <Text variant="subtitle2">{props.label}</Text>
    </HStack>
  );
};

const ProfileStatsBox = (props: { value: number; label: string }) => {
  return (
    <HStack alignItems="center" space={2}>
      <Text variant="heading6" bold>
        {props.value}
      </Text>
      <Text variant="subtitle1">{props.label}</Text>
    </HStack>
  );
};
