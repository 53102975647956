import {
  Box,
  Heading,
  HStack,
  Image,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import React, { useMemo } from "react";
import { ModalComponentProp } from "react-native-modalfy";
import { ModalStackParams } from "../../core/modals/modal-stack";
import { Dimensions, Platform, TouchableOpacity } from "react-native";
import { OptimizedCdnImage } from "../../core/components/optimzed-cdn-image";

type Props = ModalComponentProp<ModalStackParams, void, "AvatarSelectorModal">;
export const AvatarSelectorModal = (props: Props) => {
  const { width, height } = Dimensions.get("window");

  const pairs = useMemo(() => {
    const pairs = [];
    if (props.modal.params?.avatars) {
      const items = props.modal.params!.avatars!;
      for (let i = 0; i < items.length; i += 2) {
        pairs.push([items[i], items[i + 1]]);
      }
    }
    return pairs;
  }, [props.modal.params]);

  return (
    <VStack
      variant="screen"
      borderRadius="8"
      px="5"
      py="6"
      space="5"
      width={width * 0.8}
      height={height * 0.8}
      maxWidth={Platform.OS === "web" ? "625px" : width}
    >
      <VStack>
        <Heading>Choose an Avatar</Heading>
        <Text variant="small" fontSize="md">
          This will be visible across your Spark Social experience.
        </Text>
      </VStack>
      <ScrollView px="1">
        {pairs.map((avatarPair, index) => {
          return (
            <HStack
              flex="1"
              key={index}
              justifyContent={
                Platform.OS === "web" ? "space-evenly" : "space-between"
              }
            >
              {avatarPair.map((avatar) => {
                if (!avatar) return <Box m="3" height="24" width="24" />;
                return (
                  <TouchableOpacity
                    key={avatar}
                    onPress={() => {
                      props.modal.params?.onSelect(avatar);
                      props.modal.closeModal();
                    }}
                  >
                    <Box
                      m="3"
                      height="24"
                      width="24"
                      bgColor="xLightGrey"
                      borderRadius="100"
                      alignItems="center"
                      justifyContent="flex-end"
                      shadow="1"
                      overflow="hidden"
                    >
                      <OptimizedCdnImage
                        uri={avatar}
                        height={150}
                        width={150}
                        fit="cover"
                        render={(source) => (
                          <Image
                            backgroundColor="lightGrey"
                            source={source}
                            width="100%"
                            alt="Avatar"
                            height="100%"
                            resizeMethod="scale"
                            resizeMode="cover"
                          />
                        )}
                      />
                    </Box>
                  </TouchableOpacity>
                );
              })}
            </HStack>
          );
        })}
      </ScrollView>
    </VStack>
  );
};
