import Constants from "expo-constants";
import base64 from "react-native-base64";

const getImageHandlerBucketName = () => {
  const baseUrl = Constants.expoConfig?.extra?.apiBaseUrl as string;

  if (baseUrl && baseUrl.startsWith("https://api2-dev.socmedaccelerator.com")) {
    return "sma2-dev";
  }

  return "sma2-prod";
};

type GetImageUrlParams = {
  originalUrl: string;
  height?: number;
  width?: number;
  fit?: string;
};

export const getImageUrlFromCdn = ({
  originalUrl,
  width,
  height,
  fit,
}: GetImageUrlParams): string => {
  const bucketName = getImageHandlerBucketName();
  const key = removeDomainFromString(originalUrl);

  const payload = {
    bucket: bucketName,
    key: key,
    edits: {
      resize: {
        width: width,
        height: height,
        fit: fit,
      },
    },
  };

  const stringifiedPayload = JSON.stringify(payload);

  const imageCdnBaseUrl = Constants.expoConfig?.extra
    ?.imageCdnBaseUrl as string;

  return imageCdnBaseUrl + base64.encode(stringifiedPayload);
};

export const removeDomainFromString = (url: string): string => {
  const domainAndPath = url.split("://")[1]; // Remove protocol (https://)
  return domainAndPath.substring(domainAndPath.indexOf("/") + 1); // Extract path after '/'
};
