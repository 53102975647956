import { SuggestionsProvidedProps } from "react-native-controlled-mentions";
import { useAppSelector } from "../../../../store/store";
import { selectSimulatedProfiles } from "../../../../store/app.slice";
import { HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { ProfileAvatar } from "../../../core/components/profile-avatar";
import { ParticipantProfileWithPropertiesDto } from "../../../../services/api-service-sub-services/users-api-service";

const AnimatedVStack = Animated.createAnimatedComponent(VStack);

export const MentionSuggestionsList = ({
  keyword,
  onSelect,
}: SuggestionsProvidedProps) => {
  const simulatedProfiles = useAppSelector(selectSimulatedProfiles);
  const possibleProfiles = simulatedProfiles.filter((x) => {
    if (!keyword) return false;
    return x.username.toLowerCase().includes(keyword.toLowerCase());
  });

  const height = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      height: height.value,
    };
  });

  useEffect(() => {
    height.value = withTiming(possibleProfiles.length * 46, {
      duration: 150,
    });
  }, [possibleProfiles]);

  return (
    <AnimatedVStack
      mb="2"
      justifyContent="center"
      overflow="hidden"
      borderRadius="xl"
      bgColor="xLightGrey"
      borderColor="xLightGrey"
      borderWidth={possibleProfiles.length > 0 ? "1" : undefined}
      style={[animatedStyle]}
      shadow={3}
    >
      {possibleProfiles.length > 0 &&
        possibleProfiles.map((simulatedProfile) => {
          return (
            <Pressable
              justifyContent="center"
              height="46px"
              _pressed={{
                bgColor: "xLightGrey",
              }}
              key={simulatedProfile._id}
              onPress={() =>
                onSelect({
                  id: simulatedProfile._id,
                  name: simulatedProfile.username,
                })
              }
            >
              <HStack px="5" space="3" alignItems="center">
                <ProfileAvatar
                  profile={
                    simulatedProfile as unknown as ParticipantProfileWithPropertiesDto
                  }
                  length={7}
                />
                <Text variant="small">{simulatedProfile.username}</Text>
              </HStack>
            </Pressable>
          );
        })}
    </AnimatedVStack>
  );
};
