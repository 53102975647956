import { Box, HStack, Text, useTheme } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";
import React, { ReactNode } from "react";

type Props = {
  onSelect: () => void | Promise<void>;
  icon: ReactNode;
  label: string;
};

export const HorizontalActionButton = (props: Props) => {
  const theme = useTheme();
  return (
    <TouchableOpacity onPress={props.onSelect}>
      <HStack
        alignItems="center"
        space="2"
        p={3}
        borderRadius="xl"
        backgroundColor="white"
      >
        <Box
          backgroundColor="xLightGrey"
          borderRadius="xl"
          overflow="hidden"
          p={3}
        >
          {props.icon}
        </Box>
        <HStack flex={1} justifyContent="space-between" pl={1}>
          <Text variant="subtitle1" bold>
            {props.label}
          </Text>
          <MaterialIcons
            name="keyboard-arrow-right"
            size={24}
            color={theme.colors.black as unknown as string}
          />
        </HStack>
      </HStack>
    </TouchableOpacity>
  );
};
