import { registerSheet, SheetDefinition } from "react-native-actions-sheet";
import { CreateNewCommentSheet } from "./create-new-comment.sheet";
import { ParticipantProfileWithPropertiesDto } from "../../../services/api-service-sub-services/users-api-service";

type CreateNewCommentSheetProps = {
  payload: {
    content?: string;
    date?: Date | string;
    onCommentButtonPress: (content: string) => Promise<void>;
    onCancelButtonPress: (content: string) => Promise<void>;
    profile?: ParticipantProfileWithPropertiesDto;
  };
};

declare module "react-native-actions-sheet" {
  interface Sheets {
    "create-new-comment-sheet": SheetDefinition<CreateNewCommentSheetProps>;
  }
}

registerSheet("create-new-comment-sheet", CreateNewCommentSheet);

export {};
