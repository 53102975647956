import {
  Box,
  HStack,
  Menu,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  VStack,
} from "native-base";
import { ParticipantProfileWithPropertiesDto } from "../../services/api-service-sub-services/users-api-service";
import { PostReturnDto } from "../../services/api-service-sub-services/studies-api-service";
import { ProfileMetadata } from "../core/components/profile-metadata";
import React from "react";
import { PostContainer } from "../posts/post.container";
import { PostComponent } from "../posts/components/post/post.component";
import { useProfileValues } from "./hooks/use-profile-values";
import { useAppSelector } from "../../store/store";
import { selectExperienceIsConnectionsEnabled } from "../../store/app.slice";
import { CircularButton } from "../core/components/circular-button.component";
import {
  Entypo,
  MaterialCommunityIcons,
  MaterialIcons,
  SimpleLineIcons,
} from "@expo/vector-icons";
import { selectUserProfile } from "../../store/user.slice";

export type BotProfileScreenProps = {
  profile?: ParticipantProfileWithPropertiesDto;
  posts: PostReturnDto[];
  onBackButtonPress: () => void;
  isFollowedByMe?: boolean;
  onFollowUnFollowButtonPress: () => void;
  displayFollowUnFollowButton: boolean;
  onBlockButtonPress: (profileId: string) => void;
};

const Post = PostContainer(PostComponent);

export const BotProfileScreen = (props: BotProfileScreenProps) => {
  const postCount = props.posts.length;
  const profileValues = useProfileValues(props.profile?.properties);
  const userProfile = useAppSelector(selectUserProfile);

  const isConnectionsEnabled = useAppSelector(
    selectExperienceIsConnectionsEnabled
  );

  if (!props.profile) {
    return (
      <VStack
        variant="screen"
        flex="1"
        alignItems="center"
        justifyContent="center"
        safeAreaTop
      >
        <Spinner />
      </VStack>
    );
  }

  const isUserViewingOwnProfile = userProfile?._id === props.profile._id;

  return (
    <VStack
      variant="scrollable-screen"
      flex="1"
      safeAreaTop
      backgroundColor="white"
    >
      <HStack
        backgroundColor="white"
        alignItems="center"
        justifyContent="space-between"
        p={3}
      >
        <CircularButton
          onPress={props.onBackButtonPress}
          icon={<MaterialIcons name="arrow-back" size={22} />}
          shadow={1}
        />
        {!isUserViewingOwnProfile && (
          <Menu
            w="190"
            bgColor="white"
            trigger={(triggerProps) => {
              return (
                <Pressable
                  {...triggerProps}
                  _pressed={{
                    backgroundColor: "primary.100:alpha.20",
                  }}
                  backgroundColor="xLightGrey"
                  borderRadius="xl"
                  alignItems="center"
                  justifyContent="center"
                  p={2.5}
                  shadow={1}
                >
                  <MaterialCommunityIcons name="dots-horizontal" size={22} />
                </Pressable>
              );
            }}
          >
            <VStack space="2" bgColor="white">
              {props.displayFollowUnFollowButton && isConnectionsEnabled && (
                <Pressable
                  onPress={props.onFollowUnFollowButtonPress}
                  pl="4"
                  py="0.5"
                  _pressed={{
                    bgColor: "medGrey",
                  }}
                >
                  <HStack space="2" alignItems="center">
                    <SimpleLineIcons
                      name={
                        props.isFollowedByMe ? "user-unfollow" : "user-follow"
                      }
                      size={16}
                    />
                    <Text fontSize="14" letterSpacing="0.3">
                      {props.isFollowedByMe ? "Unfollow" : "Follow"}{" "}
                      {profileValues.displayName}
                    </Text>
                  </HStack>
                </Pressable>
              )}

              <Pressable
                py="0.5"
                pl="4"
                onPress={() => {
                  props.onBlockButtonPress(props.profile!._id!);
                }}
                _pressed={{
                  bgColor: "medGrey",
                }}
              >
                <HStack space="2" alignItems="center">
                  <Entypo name="block" size={16} color="red" />
                  <Text fontSize="14" letterSpacing="0.3">
                    Block {profileValues.displayName}
                  </Text>
                </HStack>
              </Pressable>
            </VStack>
          </Menu>
        )}
      </HStack>

      <ScrollView
        bgColor="xLightGrey"
        px={3}
        pt={3}
        showsVerticalScrollIndicator={false}
        pb="32px"
      >
        <VStack space={3}>
          <Box
            backgroundColor="xLightGrey"
            borderRadius="3xl"
            overflow="hidden"
          >
            <ProfileMetadata profile={props.profile} postCount={postCount} />
          </Box>

          {props.posts &&
            props.posts.length > 0 &&
            props.posts.map((post) => {
              return (
                <Post key={post._id} post={post} hideCommentSection={true} />
              );
            })}
        </VStack>
      </ScrollView>
    </VStack>
  );
};
