import React, { useRef, useState } from "react";
import { Box, FlatList, HStack, View, VStack } from "native-base";
import {
  CommentReturnDto,
  ParticipantProfileWithPropertiesDto,
} from "../../../../services/api-service-sub-services/studies-api-service";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  selectUserProfile,
  selectUserStudyId,
} from "../../../../store/user.slice";
import { ViewToken } from "react-native";
import { CreateAnalyticsItemDto } from "../../../../services/api-service-sub-services/analytics-api-service";
import dayjs from "dayjs";
import { analyticsSlice, Modules } from "../../../../store/analytics.slice";
import { useNavigation } from "@react-navigation/native";
import { Routes } from "../../../../navigation/routes";
import { CommentContent } from "./comment-content.component";
import { ThreadedComments } from "./threaded-comments.component";
import { CommentThreadItemHeader } from "./comment-thread-item-header.component";
import { CommentActions } from "./comment-actions.component";
import { getThreadedCommentsCount } from "../../../core/utils/comment.util";
import { MaterialIcons } from "@expo/vector-icons";
import { SmallActionButton } from "../../../core/components/small-action-button.component";
import { OpenCreateNewCommentSheetParams } from "../../hooks/use-open-comment-bottom-sheet.hook";
import { OnCommentInteractionButtonPressArgs } from "../../post.container";

type Props = {
  postId: string;
  defaultCommentContent?: string;
  comments: CommentReturnDto[];
  isVisible: boolean;
  highlightedCommentId?: string;
  onNewCommentTextChange?: (text: string) => void;
  openCreateNewCommentSheet?: (
    params: OpenCreateNewCommentSheetParams
  ) => Promise<void>;
  onCommentInteractionButtonPress: (
    args: OnCommentInteractionButtonPressArgs
  ) => Promise<void>;
  isThreadedCommentsEnabled?: boolean;
};

export const PostCommentsComponent = ({
  highlightedCommentId,
  defaultCommentContent = "",
  ...props
}: Props) => {
  const dispatch = useAppDispatch();
  const studyId = useAppSelector(selectUserStudyId);
  const userProfile = useAppSelector(selectUserProfile);

  const previousViewedItemsRef = useRef<ViewToken[]>([]);

  const viewabilityConfigRef = useRef({
    minimumViewTime: 100,
    viewAreaCoveragePercentThreshold: 0,
  });

  const onViewableItemsChangedRef = useRef(
    ({ viewableItems }: { viewableItems: ViewToken[] }) => {
      if (!userProfile || !studyId) {
        return;
      }

      const commentsToAddToQueue: CreateAnalyticsItemDto[] = [];

      // Filters out posts that haven't left the view yet to prevent duplicates while scrolling
      const itemsNotPreviouslyViewed = viewableItems.filter(({ item }) => {
        return !previousViewedItemsRef.current.some(
          ({ item: prevItem }) => item._id === prevItem._id
        );
      });

      itemsNotPreviouslyViewed.forEach(({ item }) => {
        commentsToAddToQueue.push({
          module: Modules.COMMENTS,
          participant: userProfile._id,
          study: studyId,
          meta: {
            description: "User viewed comment",
            timestamp: dayjs.utc().toISOString(),
            postId: props.postId,
            targetCommentId: item._id,
          },
        });
      });

      previousViewedItemsRef.current = viewableItems;

      if (commentsToAddToQueue.length) {
        dispatch(analyticsSlice.actions.addItemsToQueue(commentsToAddToQueue));
      }
    }
  );

  // if it's not set as visible
  if (!props.isVisible) {
    return null;
  }

  return (
    <Box mt={5} px={3}>
      <FlatList
        data={props.comments}
        scrollEnabled={false}
        keyExtractor={({ _id }) => _id}
        ItemSeparatorComponent={() => <View height="3" />}
        renderItem={({ item }) => (
          <CommentRenderItem
            isThreadedCommentsEnabled={props.isThreadedCommentsEnabled || false}
            comment={item}
            openCreateNewCommentSheet={props.openCreateNewCommentSheet}
            onCommentInteractionButtonPress={
              props.onCommentInteractionButtonPress
            }
          />
        )}
        viewabilityConfig={viewabilityConfigRef.current}
        onViewableItemsChanged={onViewableItemsChangedRef.current}
      />
    </Box>
  );
};

const CommentRenderItem = React.memo(
  (props: {
    isThreadedCommentsEnabled: boolean;
    comment: CommentReturnDto;
    openCreateNewCommentSheet?: (
      params: OpenCreateNewCommentSheetParams
    ) => Promise<void>;
    onCommentInteractionButtonPress: (
      args: OnCommentInteractionButtonPressArgs
    ) => Promise<void>;
  }) => {
    const navigation = useNavigation();
    const handleProfilePress = (
      profile: ParticipantProfileWithPropertiesDto | string = props.comment
        .profile
    ) => {
      // @ts-ignore
      navigation.navigate(Routes.APP_STACK__BOT_PROFILE, {
        profile: profile,
      });
    };

    const [isCommentThreadsVisible, setIsCommentsThreadVisible] =
      useState<boolean>(false);

    return (
      <HStack
        space="3"
        alignItems="flex-start"
        backgroundColor="xLightGrey"
        borderRadius="xl"
      >
        <VStack flex="1" borderRadius="xl" p="3" space={1}>
          <CommentThreadItemHeader
            date={props.comment.date}
            profile={props.comment.profile}
          />

          <CommentContent
            content={props.comment.content}
            mentionedProfiles={props.comment.taggedProfiles}
            onMentionedProfilePress={handleProfilePress}
          />

          <HStack justifyContent="space-between">
            <CommentActions
              interactionsCounter={{
                like: props.comment.likes || 0,
                dislike: props.comment.dislikes || 0,
                ...props.comment.customInteractionsCount,
              }}
              activeInteraction={props.comment.interactionByMe}
              onCommentInteractionButtonPress={async (interaction: string) => {
                await props.onCommentInteractionButtonPress({
                  commentId: props.comment._id,
                  interaction: interaction,
                });
              }}
              commentCounter={getThreadedCommentsCount(props.comment)}
              isCommentsButtonActive={isCommentThreadsVisible}
              onCommentActionButtonPress={() => {
                setIsCommentsThreadVisible((prev) => !prev);
              }}
              hideCommentButton={!props.isThreadedCommentsEnabled}
            />
            {props.isThreadedCommentsEnabled && (
              <HStack marginLeft={-2}>
                <SmallActionButton
                  onPress={() => {
                    props.openCreateNewCommentSheet?.({
                      profile: props.comment.profile,
                      content: props.comment.content,
                      parentCommentId: props.comment._id,
                    });
                  }}
                  icon={<MaterialIcons name="reply" size={18} />}
                  counterValue="Reply"
                />
              </HStack>
            )}
          </HStack>

          {isCommentThreadsVisible && (
            <Box marginTop={3}>
              <ThreadedComments
                disabled={!props.isThreadedCommentsEnabled}
                onCommentInteractionButtonPress={
                  props.onCommentInteractionButtonPress
                }
                onCreateCommentReply={props.openCreateNewCommentSheet}
                profile={props.comment.profile}
                childComments={props.comment.children || []}
              />
            </Box>
          )}
        </VStack>
      </HStack>
    );
  }
);
