import { parseDocument } from "htmlparser2";

/**
 * Determines if the given HTML string is empty.
 * @param htmlString - The HTML string to check.
 * @returns `true` if the HTML string is empty, otherwise `false`.
 */
export function isHtmlEmpty(htmlString?: string): boolean {
  if (!htmlString) return true;

  // Parse the HTML string into a DOM-like structure
  const document = parseDocument(htmlString);

  // Recursive function to check if a node is empty
  const isNodeEmpty = (node: any): boolean => {
    if (node.type === "text") {
      return node.data.trim() === ""; // Check for non-whitespace text
    }
    if (node.type === "tag") {
      if (!node.children || node.children.length === 0) return true;
      // Recursively check child nodes
      return node.children.every((child: any) =>
        isNodeEmpty(child as Element | Text)
      );
    }
    // Ignore other node types (e.g., comments, directives)
    return true;
  };

  // Start by checking the root nodes
  return document.children.every((node) => isNodeEmpty(node));
}
