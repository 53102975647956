import React, { useMemo } from "react";
import { Box, HStack, Image, Row, Text, VStack } from "native-base";
import {
  CharacteristicEntity,
  CommentReturnDto,
  PostReturnDto,
} from "../../../../services/api-service-sub-services/studies-api-service";
import { extractURLs } from "../../../core/utils/url.util";

import { LinkPreviewComponent } from "../common/link-preview.component";
import { PostCommentsComponent } from "../comments/post-comments.component";
import { useTreatmentArmExperience } from "../../../core/hooks/use-treatment-arm-experience";
import { Platform } from "react-native";
import { useModal } from "react-native-modalfy";
import { ModalStackParams } from "../../../core/modals/modal-stack";
import {
  Interaction,
  OnCommentInteractionButtonPressArgs,
} from "../../post.container";
import { ParticipantProfileWithPropertiesDto } from "../../../../services/api-service-sub-services/users-api-service";
import { TextContentRenderer } from "../common/text-content-renderer.component";
import { PostContentImage } from "./post-content-image.component";
import { PostContentVideo } from "./post-content-video.component";
import { getFirstUrl, getMediaDisplayType } from "../../utils/post.util";
import { PostHeader } from "./post-header.component";
import { PostReactions } from "./post-reactions.component";
import { OpenCreateNewCommentSheetParams } from "../../hooks/use-open-comment-bottom-sheet.hook";

export type PostComponentProps = {
  post: PostReturnDto;
  comments: CommentReturnDto[];
  isCommentSectionVisibleByDefault?: boolean;
  defaultCommentContent?: string;
  highlightedCommentId?: string;
  currentInteraction: string;
  interaction: Interaction;
  onNewCommentTextChange?: (text: string) => void;
  onCommentButtonPress: () => void;
  onInteractionButtonPress: (interaction: string) => void;
  isInteractionsLoading: boolean;
  totalComments: number;
  onProfilePress: (
    profile?: string | ParticipantProfileWithPropertiesDto
  ) => void;
  customInteractions?: CharacteristicEntity[];
  openCreateNewCommentSheet?: (
    params: OpenCreateNewCommentSheetParams
  ) => Promise<void>;
  hideCommentSection?: boolean;
  onCommentInteractionButtonPress: (
    args: OnCommentInteractionButtonPressArgs
  ) => Promise<void>;
};

export const PostComponent = (props: PostComponentProps) => {
  const { openModal } = useModal<ModalStackParams>();
  const treatmentArmExperience = useTreatmentArmExperience();

  const urls = useMemo(() => {
    return extractURLs(props.post.content);
  }, [props.post]);

  const media = getFirstUrl({ urls: props.post.mediaUrls });
  const mediaDisplayType = getMediaDisplayType({ urls: urls, media: media });
  const primaryLabel = props.post.highlight;

  const postContent = useMemo(() => {
    return props.post.content.replace(urls[0], "").trimStart().trimEnd();
  }, [props.post.content]);

  return (
    <Box backgroundColor="xLightGrey">
      <VStack
        bg="white"
        pb="3"
        borderRadius="xl"
        {...(primaryLabel
          ? {
              borderWidth: "2",
              borderColor: primaryLabel.color,
            }
          : {})}
      >
        {/* primary post content with the header */}
        <VStack
          {...(primaryLabel
            ? {
                paddingTop: "1",
              }
            : {
                paddingTop: 3,
              })}
        >
          {primaryLabel && (
            <Row
              mb={2}
              py={1}
              px={3}
              pb={1}
              alignItems="center"
              space="2"
              borderColor={primaryLabel.color}
              borderBottomWidth="2"
            >
              {primaryLabel.imageUrl && (
                <Image
                  src={primaryLabel.imageUrl}
                  alt="image"
                  height="24px"
                  width="24px"
                  maxWidth="48px"
                  resizeMode="contain"
                />
              )}
              {!primaryLabel.isTitleHidden && (
                <Text
                  variant="small"
                  color={primaryLabel.color}
                  bold
                  letterSpacing="xl"
                >
                  {!primaryLabel.isTitleHidden ? primaryLabel.title : ""}
                </Text>
              )}
            </Row>
          )}

          <VStack space="2" px={3}>
            <PostHeader
              post={props.post}
              onPress={() => {
                props.onProfilePress(props.post.profile);
              }}
            />

            <TextContentRenderer
              content={postContent}
              onMentionedProfilePress={props.onProfilePress}
              mentionedProfiles={props.post.taggedProfiles}
            />

            {/* media content such as image, and URL previews */}
            {!!mediaDisplayType && (
              <VStack mt="3" space="3">
                {mediaDisplayType === "embedded-video" && (
                  <PostContentVideo url={urls[0]} />
                )}

                {mediaDisplayType === "image" && (
                  <PostContentImage
                    onPress={() => {
                      if (Platform.OS === "web") return;
                      openModal("FullImagePreviewModal", {
                        imageURL: media!,
                      });
                    }}
                    imageUri={media as string}
                  />
                )}

                {mediaDisplayType === "url" && (
                  <LinkPreviewComponent url={urls[0]} />
                )}
              </VStack>
            )}
          </VStack>
        </VStack>

        <Box mx={3} my={2} height="0.5px" flex={1} backgroundColor="medGrey" />

        <VStack px="3">
          <Row justifyContent="space-between">
            {treatmentArmExperience.interactions.isWithVariants && (
              <VStack flex="1">
                <Row flexWrap="wrap" space="2">
                  {treatmentArmExperience.interactions.customInteractions.map(
                    (interaction) => {
                      return (
                        <HStack
                          key={interaction._id}
                          space="2"
                          alignItems="center"
                        >
                          <Text variant="small" fontSize="sm" fontWeight="500">
                            {interaction.name}
                          </Text>
                          <HStack alignItems="center" space="1">
                            <Text variant="small" fontSize="sm" bold>
                              {isNaN(props.interaction[interaction.name])
                                ? 0
                                : props.interaction[interaction.name]}
                            </Text>

                            <Image
                              height={4}
                              width={4}
                              src={interaction.icon}
                              alt="interaction"
                            />
                          </HStack>
                        </HStack>
                      );
                    }
                  )}
                </Row>
              </VStack>
            )}

            <PostReactions
              activeInteraction={props.currentInteraction}
              onInteractionButtonPress={props.onInteractionButtonPress}
              isInteractionsLoading={props.isInteractionsLoading}
              totalComments={props.totalComments}
              onCommentButtonPress={
                props.hideCommentSection
                  ? props.onCommentButtonPress
                  : undefined
              }
              interaction={props.interaction}
            />
          </Row>
        </VStack>

        {/** the comment section is rendered here */}
        <PostCommentsComponent
          isThreadedCommentsEnabled={
            treatmentArmExperience.interactions.isThreadedCommentsEnabled
          }
          openCreateNewCommentSheet={props.openCreateNewCommentSheet}
          postId={props.post._id}
          defaultCommentContent={props.defaultCommentContent}
          highlightedCommentId={props.highlightedCommentId}
          comments={props.comments}
          onNewCommentTextChange={props.onNewCommentTextChange}
          isVisible={!props.hideCommentSection}
          onCommentInteractionButtonPress={
            props.onCommentInteractionButtonPress
          }
        />
      </VStack>
    </Box>
  );
};
