import { useAppSelector } from "../../../store/store";
import {
  selectExperienceInteractionVariants,
  selectExperienceProfileVariants,
  selectStudy,
} from "../../../store/app.slice";
import { useMemo } from "react";
import _ from "lodash";
import { selectUserProfile } from "../../../store/user.slice";
import { CharacteristicEntity } from "../../../services/api-service-sub-services/studies-api-service";

export type TreatmentArmExperience = {
  interactions: {
    customInteractions: CharacteristicEntity[];
    customCommentInteractions: CharacteristicEntity[];
    isCommentsEnabled: boolean;
    isLikesEnabled: boolean;
    isDislikesEnabled: boolean;
    variants: { name: string; value: string }[];
    isWithVariants: boolean;
    variant?: string;
    isThreadedCommentsEnabled: boolean;
    isCommentLikesEnabled: boolean;
    isCommentDislikesEnabled: boolean;
  };
  profile: {
    isFirstNameEnabled: boolean;
    isLastNameEnabled: boolean;
    isNameEnabled: boolean;
    isBioEnabled: boolean;
    isLocationEnabled: boolean;
    isPoliticalAffiliationEnabled: boolean;
    isGenderEnabled: boolean;
    isAgeEnabled: boolean;
    isProfilePictureEnabled: boolean;
  };
};
export const useTreatmentArmExperience = (): TreatmentArmExperience => {
  const interactionVariants = useAppSelector(
    selectExperienceInteractionVariants
  );
  const profileVariants = useAppSelector(selectExperienceProfileVariants);
  const study = useAppSelector(selectStudy);
  const userProfile = useAppSelector(selectUserProfile);

  const interactions = useMemo(() => {
    const isLikesEnabled = interactionVariants.some(
      (variant) =>
        (variant.name === "like" && variant.value === "shown") ||
        (variant.name === "like-with-variants" && variant.value === "shown")
    );

    const isCommentLikesEnabled = interactionVariants.some(
      (variant) => variant.name === "like.comment" && variant.value === "shown"
    );

    const isDislikesEnabled = interactionVariants.some(
      (variant) =>
        (variant.name === "dislike" && variant.value === "shown") ||
        (variant.name === "dislike-with-variants" && variant.value === "shown")
    );

    const isCommentDislikesEnabled = interactionVariants.some(
      (variant) =>
        variant.name === "dislike.comment" && variant.value === "shown"
    );

    const isCommentsEnabled = interactionVariants.some(
      (variant) => variant.name === "comment" && variant.value === "shown"
    );

    const isThreadedCommentsEnabled =
      study?.characteristics.some((characteristic) => {
        return (
          characteristic.name === "comment" &&
          characteristic.modifiers.some(
            (modifier) =>
              modifier.name === "threadedModifiers" &&
              modifier.value === "enabled"
          )
        );
      }) || false;

    const isWithVariants = interactionVariants.some(
      (variant) =>
        (variant.name === "dislike-with-variants" &&
          variant.value === "shown") ||
        (variant.name === "like-with-variants" && variant.value === "shown")
    );

    const customInteractions = (
      study?.characteristics.filter((char) => char.isCustomInteraction) ?? []
    ).filter((customInteraction) => {
      return interactionVariants.some(
        (variant) =>
          variant.name === customInteraction.name && variant.value === "shown"
      );
    });

    const customCommentInteractions = (
      study?.characteristics.filter((char) => char.isCustomInteraction) ?? []
    ).filter((customInteraction) => {
      return interactionVariants.some(
        (variant) =>
          variant.name === `${customInteraction.name}.comment` &&
          variant.value === "shown"
      );
    });

    let variants: { name: string; value: string }[] = [];

    if (study && study.characteristics.length > 0) {
      study.characteristics.forEach((characteristic) => {
        if (
          characteristic.name === "like-with-variants" ||
          characteristic.name === "dislike-with-variants"
        ) {
          characteristic.modifiers.forEach((modifier) =>
            variants.push({ name: modifier.name, value: modifier.value })
          );
        }
      });
    }

    variants = _.uniqBy(variants, "name");

    // user's current variant
    const variant = userProfile?.properties.find(
      (properties) => properties.name === "political-affiliation"
    )?.value as string;

    return {
      customInteractions: customInteractions,
      customCommentInteractions: customCommentInteractions,
      isWithVariants: isWithVariants,
      isLikesEnabled: isLikesEnabled,
      isDislikesEnabled: isDislikesEnabled,
      isCommentsEnabled: isCommentsEnabled,
      isCommentDislikesEnabled: isCommentDislikesEnabled,
      isCommentLikesEnabled: isCommentLikesEnabled,
      variants: variants,
      variant: variant,
      isThreadedCommentsEnabled: isThreadedCommentsEnabled,
    };
  }, [interactionVariants, study, userProfile]);

  const profile = useMemo(() => {
    const isFirstNameEnabled = profileVariants.some(
      (variant) => variant.name === "first-name" && variant.value === "shown"
    );
    const isLastNameEnabled = profileVariants.some(
      (variant) => variant.name === "last-name" && variant.value === "shown"
    );
    const isBioEnabled = profileVariants.some(
      (variant) =>
        variant.name === "bio/description" && variant.value === "shown"
    );
    const isLocationEnabled = profileVariants.some(
      (variant) => variant.name === "location" && variant.value === "shown"
    );
    const isPoliticalAffiliationEnabled = profileVariants.some(
      (variant) =>
        variant.name === "political-affiliation" && variant.value === "shown"
    );
    const isGenderEnabled = profileVariants.some(
      (variant) => variant.name === "gender" && variant.value === "shown"
    );
    const isAgeEnabled = profileVariants.some(
      (variant) => variant.name === "age" && variant.value === "shown"
    );
    const isProfilePictureEnabled = profileVariants.some(
      (variant) =>
        variant.name === "profile-picture" && variant.value === "shown"
    );
    return {
      isFirstNameEnabled: isFirstNameEnabled,
      isLastNameEnabled: isLastNameEnabled,
      isNameEnabled: isFirstNameEnabled || isLastNameEnabled,
      isBioEnabled: isBioEnabled,
      isLocationEnabled: isLocationEnabled,
      isPoliticalAffiliationEnabled: isPoliticalAffiliationEnabled,
      isGenderEnabled: isGenderEnabled,
      isAgeEnabled: isAgeEnabled,
      isProfilePictureEnabled: isProfilePictureEnabled,
    };
  }, [profileVariants]);

  return {
    profile: profile,
    interactions: interactions,
  };
};
