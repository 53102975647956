import {
  extractFullName,
  extractNameInitials,
  extractProfileProperty,
} from "../utils/profile.util";
import { useTreatmentArmExperience } from "../../core/hooks/use-treatment-arm-experience";
import { useMemo } from "react";
import { ProfileValueEntity } from "../../../services/api-service-sub-services/studies-api-service";

export type UseProfileValuesReturn = {
  firstName?: string;
  lastName?: string;
  bioDescription?: string;
  location?: string;
  politicalAffiliation?: string;
  age?: string;
  gender?: string;
  race?: string;
  householdIncome?: string;
  profilePicture?: string;
  nameInitials: string;
  fullName: string;
  displayName: string;
};
export const useProfileValues = (
  properties?: ProfileValueEntity[]
): UseProfileValuesReturn => {
  const treatmentArmExperience = useTreatmentArmExperience();

  const {
    profile: {
      isFirstNameEnabled,
      isLastNameEnabled,
      isPoliticalAffiliationEnabled,
      isAgeEnabled,
      isProfilePictureEnabled,
      isBioEnabled,
      isGenderEnabled,
      isLocationEnabled,
    },
  } = treatmentArmExperience;

  const firstName = extractProfileProperty("first-name", properties);
  const lastName = extractProfileProperty("last-name", properties);
  const bioDescription = extractProfileProperty("bio/description", properties);
  const location = extractProfileProperty("location", properties);
  const politicalAffiliation = extractProfileProperty(
    "political-affiliation",
    properties
  );
  const age = extractProfileProperty("age", properties);
  const gender = extractProfileProperty("gender", properties);
  const race = extractProfileProperty("race", properties);
  const householdIncome = extractProfileProperty(
    "household-income",
    properties
  );
  const profilePicture = extractProfileProperty("profile-picture", properties);

  const displayName = useMemo<string>((): string => {
    const isFirstNameEnabled =
      treatmentArmExperience.profile.isFirstNameEnabled;
    const isLastNameEnabled = treatmentArmExperience.profile.isLastNameEnabled;

    if (isFirstNameEnabled && isLastNameEnabled) {
      return `${firstName} ${lastName}`;
    }

    if (isFirstNameEnabled && firstName && !isLastNameEnabled) {
      return firstName;
    }

    if (!isFirstNameEnabled && isLastNameEnabled && lastName) {
      return lastName;
    }

    return "Anonymous User";
  }, [treatmentArmExperience, firstName, lastName]);

  return {
    firstName: isFirstNameEnabled ? firstName : undefined,
    lastName: isLastNameEnabled ? lastName : undefined,
    nameInitials: extractNameInitials(properties),
    fullName: extractFullName(properties),
    displayName: displayName,
    bioDescription: isBioEnabled ? bioDescription : undefined,
    location: isLocationEnabled ? location : undefined,
    politicalAffiliation: isPoliticalAffiliationEnabled
      ? politicalAffiliation
      : undefined,
    age: isAgeEnabled ? age : undefined,
    gender: isGenderEnabled ? gender : undefined,
    race: race,
    householdIncome: householdIncome,
    profilePicture: isProfilePictureEnabled ? profilePicture : undefined,
  };
};
