import { createContext, PropsWithChildren, useEffect, useRef } from "react";
import { ConfiguredPopupDto } from "../../../services/api-service-sub-services/studies-api-service";
import { useAppSelector } from "../../../store/store";
import { selectTreatmentArmExperience } from "../../../store/app.slice";
import { selectUserProfile } from "../../../store/user.slice";
import dayjs from "dayjs";
import { getModalInstructions, getModalType } from "../utils/get-modal-type";
import { useModal } from "react-native-modalfy";
import { ModalStackParams } from "../modals/modal-stack";
import { logger } from "../utils/logger.util";

type TimeBasedPopupsContextType = {};

const TimeBasedPopupsContext = createContext<
  TimeBasedPopupsContextType | undefined
>(undefined);

export const TimeBasedPopupsProvider = (props: PropsWithChildren) => {
  const { openModal } = useModal<ModalStackParams>();

  const treatmentArm = useAppSelector(selectTreatmentArmExperience);
  const profile = useAppSelector(selectUserProfile);

  const baseTime = dayjs(profile?.enteredNewsfeedAt);
  const { timeBasedPopups: popups } = treatmentArm;

  const timersRef = useRef<NodeJS.Timeout[]>([]);

  useEffect(() => {
    if (!profile?.enteredNewsfeedAt) {
      return;
    }

    popups.forEach((popup: ConfiguredPopupDto) => {
      // @ts-expect-error
      const timeToAdd = popup.meta!.time!;
      const [hh, mm] = timeToAdd.split(":");

      const popupTime = dayjs(baseTime)
        .add(parseInt(hh), "hour")
        .add(parseInt(mm), "minutes");
      const now = dayjs();

      // only schedule the popup if the time is in the future
      if (now.isBefore(popupTime)) {
        const delayInMs = popupTime.diff(now, "milliseconds");

        const timer = setTimeout(() => {
          logger.info("Time based popup displayed", popup);
          openModal("InstructionsModal", {
            type: getModalType(popup),
            instructions: getModalInstructions(popup),
          });
        }, delayInMs);

        timersRef.current.push(timer);

        logger.info("Time based popup added", {
          id: popup._id,
          delayInMs: delayInMs,
          popupTime: popupTime.toISOString(),
        });
      }
    });

    return () => {
      timersRef.current.forEach(clearTimeout);
      timersRef.current = [];
    };
  }, [popups, baseTime]);

  return (
    <TimeBasedPopupsContext.Provider value={{}}>
      {props.children}
    </TimeBasedPopupsContext.Provider>
  );
};
