import { ConfiguredPopupDto } from "../../../services/api-service-sub-services/studies-api-service";

/**
 * Returns the modal type for the popup windows
 * @param popup
 */
export const getModalType = (popup: ConfiguredPopupDto) => {
  if (!!popup.popup.content) {
    return "html";
  } else if (!!popup.popup.survey) {
    return "qualtrics";
  }
  return "html";
};

export const getModalInstructions = (
  popup: ConfiguredPopupDto,
  textReplacementSet?: Record<string, string>
): string => {
  const modalType = getModalType(popup);
  let content = "";

  if (modalType === "html") {
    content = popup.popup.content!;
    if (!!textReplacementSet) {
      for (const [key, value] of Object.entries(textReplacementSet)) {
        content = content.replace(new RegExp(`\\$\\{${key}\\}`, "g"), value);
      }
    }
  } else if (modalType === "qualtrics") {
    content = popup.popup.survey?.id!;
  }
  return content;
};
