import React from "react";
import { Dimensions, Platform, TouchableOpacity } from "react-native";
import { Box, VStack } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { ModalComponentProp } from "react-native-modalfy";
import { ModalStackParams } from "./modal-stack";
import { ImageZoom } from "@likashefqet/react-native-image-zoom";
import { StatusBar } from "expo-status-bar";

type Props = {} & ModalComponentProp<
  ModalStackParams,
  void,
  "FullImagePreviewModal"
>;

// @ts-ignore
export const FullImagePreviewModal = (props: Props) => {
  const { width, height } = Dimensions.get("window");
  return (
    <VStack
      backgroundColor="black"
      width={width}
      height={height}
      maxWidth={Platform.OS === "web" ? "625px" : width}
    >
      <StatusBar hidden />
      <ImageZoom
        uri={props.modal.params!.imageURL}
        minScale={1}
        maxScale={5}
        resizeMode="contain"
      />
      <Box
        position="absolute"
        top="8"
        left="3"
        bgColor="transparent"
        p="2"
        zIndex="10"
      >
        <TouchableOpacity
          onPress={() => {
            props.modal.closeModal();
          }}
        >
          <AntDesign name="closecircle" size={28} color="white" />
        </TouchableOpacity>
      </Box>
    </VStack>
  );
};
