import { CommentReturnDto } from "../../../../services/api-service-sub-services/studies-api-service";
import React, { PropsWithChildren, useState } from "react";
import { Box, Divider, HStack, useTheme, VStack } from "native-base";
import { CommentThreadItemHeader } from "./comment-thread-item-header.component";
import { CommentContent } from "./comment-content.component";
import { MaterialIcons } from "@expo/vector-icons";
import { ProfileAvatar } from "../../../core/components/profile-avatar";
import { CommentActions } from "./comment-actions.component";
import { SmallActionButton } from "../../../core/components/small-action-button.component";
import { OpenCreateNewCommentSheetParams } from "../../hooks/use-open-comment-bottom-sheet.hook";
import { OnCommentInteractionButtonPressArgs } from "../../post.container";

export type CommentThreadItemProps = {
  comment: CommentReturnDto;
  hasNoTracer?: boolean;
  hideLineBar?: boolean;
  onCreateCommentReply: (params: OpenCreateNewCommentSheetParams) => void;
  level?: number;
  hideReplyButton?: boolean;
  onCommentInteractionButtonPress: (
    args: OnCommentInteractionButtonPressArgs
  ) => Promise<void>;
};

export const CommentThreadItem = (
  props: PropsWithChildren<CommentThreadItemProps>
) => {
  const theme = useTheme();
  const [isChildrenVisible, setIsChildrenVisible] = useState(false);

  return (
    <HStack space="1">
      <VStack alignItems="center">
        {!props.hasNoTracer && (
          <ProfileAvatar profile={props.comment.profile} length="8" />
        )}
        <Box
          width="1px"
          flex={1}
          bgColor={!props.hideLineBar ? "medGrey" : undefined}
        />
      </VStack>

      <VStack flex="1" borderRadius="xl" pl="1">
        <CommentThreadItemHeader
          hideAvatar={!props.hasNoTracer}
          date={props.comment.date}
          profile={props.comment.profile}
        />

        <CommentContent
          content={props.comment.content}
          onMentionedProfilePress={() => {}}
        />

        <Box marginTop={2}>
          <Divider height="1px" color="medGrey" />
        </Box>

        <HStack
          space={2}
          alignItems="center"
          justifyContent="space-between"
          marginTop={1}
          marginBottom={2}
        >
          <Box marginLeft={-2}>
            <CommentActions
              interactionsCounter={{
                like: props.comment.likes || 0,
                dislike: props.comment.dislikes || 0,
                ...props.comment.customInteractionsCount,
              }}
              onCommentInteractionButtonPress={async (interaction: string) => {
                await props.onCommentInteractionButtonPress({
                  commentId: props.comment._id,
                  interaction: interaction,
                });
              }}
              activeInteraction={props.comment.interactionByMe}
              hideCommentButton={
                props.hideReplyButton ||
                React.Children.count(props.children) < 1
              }
              onCommentActionButtonPress={() => {
                if (React.Children.count(props.children) < 1) {
                  return;
                }
                setIsChildrenVisible((prev) => !prev);
              }}
              isCommentsButtonActive={isChildrenVisible}
              commentCounter={React.Children.count(props.children)}
            />
          </Box>
          {!props.hideReplyButton && (
            <HStack marginLeft={-2}>
              <SmallActionButton
                onPress={() => {
                  props.onCreateCommentReply({
                    content: props.comment.content,
                    profile: props.comment.profile,
                    parentCommentId: props.comment._id,
                  });
                }}
                icon={<MaterialIcons name="reply" size={18} />}
                counterValue="Reply"
              />
            </HStack>
          )}
        </HStack>

        {isChildrenVisible && <VStack>{props.children}</VStack>}
      </VStack>
    </HStack>
  );
};
