import getVideoId from "get-video-id";

export const getMediaDisplayType = (args: {
  urls: string[];
  media?: string;
}) => {
  const { urls, media } = args;

  if (urls?.length) {
    const { id, service } = getVideoId(urls[0]);
    if (id && (service === "youtube" || service === "vimeo")) {
      return "embedded-video";
    }
    return "url";
  }
  return media ? "image" : undefined;
};

export const getFirstUrl = (args: { urls: string[] }) => {
  return args.urls[0] ?? undefined;
};
