import React from "react";
import { Box, ScrollView, Text, VStack } from "native-base";
import { ProfileMetadata } from "../core/components/profile-metadata";
import { PostComponent } from "../posts/components/post/post.component";
import { PostReturnDto } from "../../services/api-service-sub-services/studies-api-service";
import { PostContainer } from "../posts/post.container";
import { ParticipantProfileWithPropertiesDto } from "../../services/api-service-sub-services/users-api-service";
import { NoPostIcon } from "../core/icons/no-post-icon";
import { NewPostPromptSmall } from "../posts/components/post/new-post-prompt-small";

const Post = PostContainer(PostComponent);

export type ProfileScreenProps = {
  posts?: PostReturnDto[];
  profile: ParticipantProfileWithPropertiesDto;
  postCount: number;
  onNewPostButtonPress: () => void;
};

export const ProfileScreen = React.memo((props: ProfileScreenProps) => {
  return (
    <VStack variant="scrollable-screen" safeAreaTop>
      <VStack space="3" backgroundColor="xLightGrey">
        <ProfileMetadata profile={props.profile} postCount={props.postCount} />
      </VStack>

      <VStack backgroundColor="xLightGrey" flex={1}>
        <Box
          overflow="hidden"
          p={3}
          borderRadius="xl"
          width={"100%"}
          backgroundColor="xLightGrey"
        >
          <NewPostPromptSmall
            onPress={props.onNewPostButtonPress}
            profile={props.profile}
          />
        </Box>

        {props.posts && props.posts.length > 0 ? (
          <ScrollView
            flex="1"
            px={3}
            showsVerticalScrollIndicator={false}
            backgroundColor="xLightGrey"
            borderTopRadius={"3xl"}
            pb="32px"
          >
            <VStack space="3">
              {props.posts.map((post) => {
                return (
                  <Post key={post._id} post={post} hideCommentSection={true} />
                );
              })}
            </VStack>
          </ScrollView>
        ) : (
          <VStack
            alignItems="center"
            justifyContent="center"
            flex="1"
            backgroundColor="xLightGrey"
          >
            <NoPostIcon />
            <Text variant="heading" fontWeight="700">
              No posts
            </Text>
            <Text fontSize="lg">
              Here's where your created posts will show up
            </Text>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
});
