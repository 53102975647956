import { useAppSelector } from "../../../store/store";
import {
  selectUserProfile,
  selectUserStudyId,
} from "../../../store/user.slice";
import { useStudiesControllerHandleOverrideMutation } from "../../../services/api-service-sub-services/studies-api-service";

export type UseSurveyStatusOverrideReturn = {
  override: (
    surveyType: "pre-treatment" | "post-treatment",
    responseId: string
  ) => Promise<void>;
};

/**
 * Returns a function that allows you to override Qualtrics webhooks. This was
 * implemented because Qualtrics has a delay issue, and we are now catching
 * redirects to `https://spark-social.com/app/redirect
 */
export const useSurveyStatusOverride = (): UseSurveyStatusOverrideReturn => {
  const profile = useAppSelector(selectUserProfile);
  const studyId = useAppSelector(selectUserStudyId);
  const participantCode = profile?.participantCode;
  const [overrideSurveyStatus] = useStudiesControllerHandleOverrideMutation();

  const override = async (
    surveyType: "pre-treatment" | "post-treatment",
    responseId: string
  ) => {
    if (!studyId || !participantCode) return;

    await overrideSurveyStatus({
      id: studyId,
      createStudySurveyResponseOverrideDto: {
        participantCode: participantCode,
        responseId: responseId,
        surveyType: surveyType,
      },
    }).unwrap();
  };

  return {
    override: override,
  };
};
