import { HStack, Text } from "native-base";
import { ProfileAvatar } from "../../../core/components/profile-avatar";
import React from "react";
import { ParticipantProfileWithPropertiesDto } from "../../../../services/api-service-sub-services/studies-api-service";

export type CreateNewCommentPlaceholderProps = {
  profile?: ParticipantProfileWithPropertiesDto;
};

export const CreateNewCommentPlaceholder = (
  props: CreateNewCommentPlaceholderProps
) => {
  return (
    <HStack
      alignItems="center"
      justifyContent="flex-start"
      backgroundColor="xLightGrey"
      borderRadius="xl"
      padding={2}
      space={2}
    >
      {/** avatar of the current user */}
      <ProfileAvatar profile={props.profile} length="8" />
      <Text variant="label">Submit a new reply...</Text>
    </HStack>
  );
};
