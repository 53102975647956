import {
  createModalStack,
  ModalOptions,
  ModalStackConfig,
} from "react-native-modalfy";
import { ErrorModal } from "./error-modal";
import { InstructionsModal } from "./instructions-modal";
import { FullImagePreviewModal } from "./full-image-preview-modal";
import { AvatarSelectorModal } from "../../profile/components/avatar-selector-modal";

const config: ModalStackConfig = {
  ErrorModal,
  InstructionsModal,
  FullImagePreviewModal,
  AvatarSelectorModal,
};

const options: ModalOptions = {
  backdropOpacity: 0.7,
  backBehavior: "none",
  disableFlingGesture: true,
};

export type ModalStackParams = {
  ErrorModal: {
    message: string;
    onContinue?: () => Promise<void> | void;
  };
  InstructionsModal: {
    type?: "html" | "qualtrics";
    instructions: string;
    onClose?: () => void;
    continueActionType?: "button" | "time" | "checkbox";
    continueActionMeta?: {
      buttonLabel?: string;
      checkboxLabel?: string;
      time?: number;
      isCheckboxRequired?: boolean;
    };
  };
  FullImagePreviewModal: {
    imageURL: string;
  };
  AvatarSelectorModal: {
    avatars: string[]; // the uris of the avatars
    onSelect: (avatar: string) => void;
  };
};

export const modalStack = createModalStack(config, options);
