import React from "react";
import { Box, HStack, Text, VStack } from "native-base";
import WebView from "react-native-webview";
import { MaterialIcons } from "@expo/vector-icons";
import { Platform } from "react-native";
import { CircularButton } from "../core/components/circular-button.component";

export type InAppBrowserScreenProps = {
  url: string;
  title?: string;
  userPressedCloseButton: () => void;
  onInAppBrowserURLChange: (url: string) => void;
};

export const InAppBrowserScreen = (props: InAppBrowserScreenProps) => {
  return (
    <VStack variant="screen" flex="1" safeAreaTop>
      <HStack
        space="3"
        px={3}
        borderBottomColor="medGrey"
        borderBottomWidth="0.5"
        py="3"
        alignItems="center"
      >
        <CircularButton onPress={props.userPressedCloseButton}>
          <MaterialIcons name="close" size={22} />
        </CircularButton>

        <Box flex="1">
          <Text
            numberOfLines={1}
            overflow="hidden"
            variant={!!props.title ? "heading6" : "body1"}
            fontWeight="600"
            color="text-black"
          >
            {props.title || props.url}
          </Text>
        </Box>
      </HStack>
      {Platform.OS === "web" && <iframe src={props.url} style={{ flex: 1 }} />}
      {Platform.OS !== "web" && (
        <WebView
          androidLayerType="software"
          onNavigationStateChange={(event) => {
            props.onInAppBrowserURLChange(event.url);
          }}
          source={{ uri: props.url }}
          style={{
            flex: 1,
          }}
        />
      )}
    </VStack>
  );
};
