import React, { PropsWithChildren } from "react";
import { Box } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";

type ElevatedPaperProps = {} & InterfaceBoxProps;

export const ElevatedPaper = ({
  children,
  ...props
}: PropsWithChildren<ElevatedPaperProps>) => {
  return (
    <Box
      alignItems="center"
      p={3}
      borderRadius="xl"
      backgroundColor="white"
      {...props}
    >
      {children}
    </Box>
  );
};
