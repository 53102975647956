import React, { useCallback, useEffect } from "react";
import { PostComponentProps } from "./components/post/post.component";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectUserProfile, selectUserStudyId } from "../../store/user.slice";
import {
  ParticipantProfileWithPropertiesDto,
  useStudiesControllerFindPostCommentsByPostIdQuery,
  useStudiesControllerUpsertInteractionForCommentMutation,
  useStudiesControllerUpsertInteractionForPostMutation,
} from "../../services/api-service-sub-services/studies-api-service";
import dayjs from "dayjs";
import { analyticsSlice, Modules } from "../../store/analytics.slice";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { useTreatmentArmExperience } from "../core/hooks/use-treatment-arm-experience";
import { Routes } from "../../navigation/routes";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { useReactivePopupsSideEffect } from "../core/hooks/use-reactive-popups-side-effect";
import { postSlice, selectPostInteractions } from "../../store/post.slice";
import { useOpenCommentBottomSheet } from "./hooks/use-open-comment-bottom-sheet.hook";
import { logger } from "../core/utils/logger.util";
import { events } from "../../constants/analytics.constants";

// TODO: REFACTOR THESE
export type PostContainerProps = Pick<
  PostComponentProps,
  | "post"
  | "defaultCommentContent"
  | "isCommentSectionVisibleByDefault"
  | "highlightedCommentId"
  | "onNewCommentTextChange"
> & {
  hideCommentSection?: boolean;
};

export type Interaction = {
  [key: string]: number;
};

export type OnCommentInteractionButtonPressArgs = {
  interaction: string;
  commentId: string;
};

export const PostContainer = (Component: React.FC<PostComponentProps>) => {
  return function _(props: PostContainerProps) {
    const track = useTrackEvent();
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const dispatch = useAppDispatch();

    const studyId = useAppSelector(selectUserStudyId);
    const userProfile = useAppSelector(selectUserProfile);
    const treatmentArmExperience = useTreatmentArmExperience();
    const { triggerPopupSideEffect } = useReactivePopupsSideEffect();

    // comments
    const { data: paginatedComments, refetch: refetchComments } =
      useStudiesControllerFindPostCommentsByPostIdQuery(
        { id: studyId as string, postId: props.post._id, limit: 100 },
        // Return only the necessary data since this will be constantly querying
        {
          skip: !isFocused,
          pollingInterval: 900,
          selectFromResult: ({ data }) => ({ data }),
        }
      );

    // mutation for saving the interaction pressed by the user
    const [saveInteraction, { isLoading: isSavingInteraction }] =
      useStudiesControllerUpsertInteractionForPostMutation({
        selectFromResult: ({ isLoading }) => ({ isLoading }),
      });

    const [saveCommentInteraction, { isLoading: isSavingCommentInteraction }] =
      useStudiesControllerUpsertInteractionForCommentMutation({
        selectFromResult: ({ isLoading }) => ({ isLoading }),
      });

    const mappedCustomInteractionsToCounterObject =
      treatmentArmExperience.interactions.customInteractions.reduce<{
        [key: string]: number;
      }>((object, item) => {
        object[item.name] = 0;
        return object;
      }, {});

    useEffect(() => {
      dispatch(
        postSlice.actions.initializedPostInteractions({
          postId: props.post._id,
          data: {
            activeInteraction: props.post.interactionByMe ?? "none",
            counters: {
              like: props.post.likes || 0,
              dislike: props.post.dislikes || 0,
              ...mappedCustomInteractionsToCounterObject,
              ...props.post.customInteractionsCount,
            },
          },
        })
      );
    }, []);

    const {
      counters: interactionCounters,
      activeInteraction: currentInteraction,
    } = useAppSelector(selectPostInteractions(props.post._id));

    const handleCommentInteractionButtonPress = async (
      args: OnCommentInteractionButtonPressArgs
    ) => {
      if (!studyId || !userProfile || isSavingCommentInteraction) {
        return;
      }

      try {
        const isCustomInteraction =
          treatmentArmExperience.interactions.customInteractions.some(
            (i) => i.name === args.interaction
          );

        // mutation to save the interaction
        const result = await saveCommentInteraction({
          id: studyId,
          commentId: args.commentId,
          createInteractionDto: {
            action: args.interaction,
            profile: userProfile._id,
            affiliation:
              treatmentArmExperience.interactions.isWithVariants &&
              !isCustomInteraction
                ? treatmentArmExperience.interactions.variant!
                : "",
          },
        }).unwrap();

        track(events.userAddedInteractionToComment, {
          interaction: args.interaction,
          commentId: args.commentId,
        });
      } catch (e) {
        logger.error(e, {
          message: "Failed to submit comment interaction",
          interaction: args.interaction,
        });
      }
    };

    const onInteractionButtonPress = async (interaction: string) => {
      if (!studyId || !userProfile || isSavingInteraction) {
        return;
      }
      try {
        const isCustomInteraction =
          treatmentArmExperience.interactions.customInteractions.some(
            (i) => i.name === interaction
          );

        await saveInteraction({
          id: studyId,
          postId: props.post._id,
          createInteractionDto: {
            action: interaction,
            profile: userProfile._id,
            affiliation:
              treatmentArmExperience.interactions.isWithVariants &&
              !isCustomInteraction
                ? treatmentArmExperience.interactions.variant!
                : "",
          },
        }).unwrap();

        dispatch(
          postSlice.actions.receivedInteractionUpdate({
            postId: props.post._id,
            data: {
              interaction: interaction,
            },
          })
        );

        dispatch(
          analyticsSlice.actions.addItemToQueue({
            study: studyId,
            participant: userProfile._id,
            module: Modules.INTERACTIONS,
            meta: {
              description: `User modified interaction to post`,
              interaction: interaction,
              postId: props.post._id,
              timestamp: dayjs.utc().toISOString(),
            },
          })
        );

        await triggerPopupSideEffect("when-user-engages-post");

        track(events.userAddedInteractionToPost, {
          interaction: interaction,
          postId: props.post._id,
        });
      } catch (e) {
        logger.error("Interaction failed to submit", e);
      }
    };

    const handleOnProfilePress = useCallback(
      (profile?: string | ParticipantProfileWithPropertiesDto) => {
        // @ts-ignore
        navigation.navigate(Routes.APP_STACK__BOT_PROFILE, {
          profile: profile,
        });
      },
      [props.post.profile, navigation]
    );

    const [openCreateNewCommentSheet] = useOpenCommentBottomSheet({
      onSuccessCallback: () => {
        refetchComments();
      },
      postId: props.post._id,
    });

    return (
      <Component
        hideCommentSection={props.hideCommentSection}
        customInteractions={
          treatmentArmExperience.interactions.customInteractions
        }
        onProfilePress={handleOnProfilePress}
        interaction={interactionCounters}
        currentInteraction={currentInteraction}
        highlightedCommentId={props.highlightedCommentId}
        defaultCommentContent={props.defaultCommentContent}
        isCommentSectionVisibleByDefault={
          props.isCommentSectionVisibleByDefault
        }
        post={props.post}
        isInteractionsLoading={isSavingInteraction}
        comments={paginatedComments?.docs || []}
        totalComments={paginatedComments?.totalDocs || 0}
        onNewCommentTextChange={props.onNewCommentTextChange}
        onInteractionButtonPress={onInteractionButtonPress}
        onCommentInteractionButtonPress={handleCommentInteractionButtonPress}
        openCreateNewCommentSheet={openCreateNewCommentSheet}
        onCommentButtonPress={() => {
          if (!userProfile || !studyId) {
            return;
          }

          // @ts-ignore
          navigation.navigate(Routes.APP_STACK__SINGLE_POST, {
            postId: props.post._id,
          });
        }}
      />
    );
  };
};
