import React, { useEffect, useMemo, useState } from "react";
import { OnboardingSurveyScreenProps } from "./onboarding-survey.screen";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  selectUserId,
  selectUserProfile,
  selectUserStudyId,
  userSlice,
} from "../../store/user.slice";
import {
  useLazyStudiesControllerGetTreatmentArmQuery,
  useStudiesControllerGetParticipantCodeQuery,
  useStudiesControllerGetStudyQuery,
} from "../../services/api-service-sub-services/studies-api-service";
import { useUsersControllerGetProfileQuery } from "../../services/api-service-sub-services/users-api-service";
import { NativeStackScreenProps } from "react-native-screens/native-stack";
import { AppStackParamList } from "../../navigation/navigators/app-stack.navigator";
import { Routes } from "../../navigation/routes";
import { appSlice, logoutAction } from "../../store/app.slice";
import { useToast } from "native-base";
import { useTrackEvent } from "../core/hooks/use-track-event";
import { events } from "../../constants/analytics.constants";
import { ShouldStartLoadRequest } from "react-native-webview/lib/WebViewTypes";
import { useSurveyStatusOverride } from "./hooks/use-survey-status-override";
import queryString from "query-string";
import { logger } from "../core/utils/logger.util";

type ContainerProps = NativeStackScreenProps<
  AppStackParamList,
  Routes.APP_STACK__ONBOARDING_SURVEY
> & {};
export const OnboardingSurveyContainer = (
  Screen: React.FC<OnboardingSurveyScreenProps>
) => {
  return function _(props: ContainerProps) {
    const toast = useToast();

    const [isLoading, setIsPending] = useState(false);
    const track = useTrackEvent();
    const dispatch = useAppDispatch();

    const studyId = useAppSelector(selectUserStudyId);
    const userId = useAppSelector(selectUserId);
    const profile = useAppSelector(selectUserProfile);

    const [getTreatmentArm] = useLazyStudiesControllerGetTreatmentArmQuery();

    const participantCode = profile?.participantCode;

    const { override } = useSurveyStatusOverride();

    const { data: study } = useStudiesControllerGetStudyQuery(
      {
        id: studyId!,
      },
      {
        skip: !studyId,
        refetchOnMountOrArgChange: true,
      }
    );

    const handleShouldStartLoad = (request: ShouldStartLoadRequest) => {
      const redirectUrl = "https://spark-social.com/app/redirect";
      if (request.url && request.url.startsWith(redirectUrl)) {
        setIsPending(true);
        const args = queryString.parse(request.url.split("?").pop() || "");
        override("pre-treatment", args["response_id"] as string);

        // prevent redirect from loading
        return false;
      }

      // allow other requests to redirect
      return true;
    };

    const { data: polledProfile, isLoading: isPolledProfileLoading } =
      useUsersControllerGetProfileQuery(
        {
          id: userId!,
        },
        {
          pollingInterval: 777,
          skip: !userId,
        }
      );

    const { data: polledCode, isLoading: isPolledCodeLoading } =
      useStudiesControllerGetParticipantCodeQuery(
        {
          id: studyId!,
          code: participantCode!,
        },
        {
          pollingInterval: 777,
          skip: !studyId || !participantCode,
        }
      );

    useEffect(() => {
      const loadProfileValuesAndTreatmentArm = async () => {
        try {
          if (!studyId) {
            return;
          }
          dispatch(userSlice.actions.userProfileFetched(polledProfile!));
          const treatmentArm = await getTreatmentArm({
            id: studyId,
            treatmentArmId: polledProfile!.treatmentArm!,
          }).unwrap();
          dispatch(appSlice.actions.treatmentArmReceived(treatmentArm));
          track(events.userCompletedSurvey, {
            type: "onboarding_survey",
          });
          props.navigation.replace(Routes.APP_STACK__MAIN);
        } catch (e) {
          logger.error(e);
        }
      };

      if (
        polledCode?.isUsedForOnboardingSurvey &&
        polledProfile?.treatmentArm
      ) {
        loadProfileValuesAndTreatmentArm();
      }
    }, [polledProfile, polledCode, studyId]);

    useEffect(() => {
      const redirect = async () => {
        try {
          if (study && profile && profile.treatmentArm) {
            const treatmentArm = await getTreatmentArm({
              id: study._id,
              treatmentArmId: profile.treatmentArm,
            }).unwrap();
            dispatch(appSlice.actions.treatmentArmReceived(treatmentArm));
            track(events.userRedirectedFromAnExistingCompletedSurvey, {
              type: "onboarding_survey",
            });
            props.navigation.replace(Routes.APP_STACK__MAIN);
          } else {
            // TODO do something if the arm assignment is based on qualtrics, and there's no qualtrics survey linked to the study
            dispatch(logoutAction());
            toast.show({
              description:
                "You have used a misconfigured code. Please contact your administrator for help.",
            });
          }
        } catch (e) {
          logger.error(e);
        }
      };

      if (study && profile) {
        if (!study.onboardingSurveyId) {
          redirect();
        }
      }
    }, [study, profile]);

    const qualtricsSurveyUrl = useMemo(() => {
      if (participantCode && study?.onboardingSurveyId) {
        return `https://duke.qualtrics.com/jfe/form/${study.onboardingSurveyId}?participant_code=${participantCode}`;
      }
      return undefined;
    }, [participantCode, study]);

    return (
      <Screen
        qualtricsSurveyURL={qualtricsSurveyUrl}
        isLoading={isPolledCodeLoading || isPolledProfileLoading || isLoading}
        handleUrlRedirects={handleShouldStartLoad}
      />
    );
  };
};
