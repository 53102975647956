import { Box, HStack, Text, useTheme, VStack } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import React, { useCallback } from "react";
import { ElevatedPaper } from "../core/components/elevated-paper.component";
import { HorizontalActionButton } from "../core/components/horizontal-action-button.component";
import { formatDateTimeRelative } from "../core/utils/date.util";
import dayjs from "dayjs";
import { ParticipantProfileWithPropertiesDto } from "../../services/api-service-sub-services/studies-api-service";
import { useFocusEffect } from "@react-navigation/native";

export type SettingsScreenProps = {
  onLogoutButtonPress: () => void;
  onReportProblemButtonPress: () => void;
  profile: ParticipantProfileWithPropertiesDto;
};

export const SettingsScreen = (props: SettingsScreenProps) => {
  const theme = useTheme();
  const [sessionInformationValues, setSessionInformationValues] =
    React.useState({
      timeLeft: formatDateTimeRelative(
        dayjs(props.profile?.exitSurveySchedule!).toDate()
      ),
      timePassed: formatDateTimeRelative(
        dayjs(props.profile?.enteredNewsfeedAt!).toDate()
      ),
    });

  useFocusEffect(
    useCallback(() => {
      setSessionInformationValues({
        timeLeft: formatDateTimeRelative(
          dayjs(props.profile?.exitSurveySchedule!).toDate()
        ),
        timePassed: formatDateTimeRelative(
          dayjs(props.profile?.enteredNewsfeedAt!).toDate()
        ),
      });
    }, [])
  );

  return (
    <VStack
      px="5"
      variant="screen"
      safeAreaTop
      flex={1}
      space={3}
      backgroundColor="xLightGrey"
    >
      {/*heading*/}
      <Box>
        <Text variant="heading5">Preferences</Text>
      </Box>

      <VStack space={2}>
        <Text variant="subtitle1" bold>
          Session information
        </Text>
        <ElevatedPaper>
          <VStack alignItems="flex-start" justifyContent="flex-start" space={3}>
            <HStack justifyContent="flex-start" alignItems="center" space={3}>
              <Box
                backgroundColor="xLightGrey"
                borderRadius="xl"
                overflow="hidden"
                p={3}
              >
                <MaterialIcons
                  size={16}
                  name="access-time"
                  color={theme.colors.secondary as unknown as string}
                />
              </Box>
              <Text variant="subtitle1" textAlign="left">
                Time left
              </Text>
              <Text
                variant="subtitle1"
                textAlign="right"
                fontWeight="600"
                flex={1}
              >
                {sessionInformationValues.timeLeft}
              </Text>
            </HStack>
            <HStack justifyContent="flex-start" alignItems="center" space={3}>
              <Box
                backgroundColor="xLightGrey"
                borderRadius="xl"
                overflow="hidden"
                p={3}
              >
                <MaterialIcons
                  size={16}
                  name="calendar-today"
                  color={theme.colors.primary as unknown as string}
                />
              </Box>
              <Text variant="subtitle1" textAlign="left">
                Time passed
              </Text>
              <Text
                variant="subtitle1"
                textAlign="right"
                fontWeight="600"
                flex={1}
              >
                {sessionInformationValues.timePassed}
              </Text>
            </HStack>
            <HStack justifyContent="flex-start" alignItems="center" space={3}>
              <Box
                backgroundColor="xLightGrey"
                borderRadius="xl"
                overflow="hidden"
                p={3}
              >
                <MaterialIcons
                  size={16}
                  name="info-outline"
                  color={theme.colors["black"] as unknown as string}
                />
              </Box>
              <Text variant="subtitle1" textAlign="left">
                Invitation code
              </Text>
              <Text
                variant="subtitle1"
                textAlign="right"
                fontWeight="600"
                flex={1}
                letterSpacing={1}
              >
                {props.profile?.participantCode?.toUpperCase()}
              </Text>
            </HStack>
          </VStack>
        </ElevatedPaper>
      </VStack>

      {/*Account settings*/}
      <VStack space={2}>
        <Text variant="subtitle1" bold>
          Session settings
        </Text>
        <VStack space={3}>
          <HorizontalActionButton
            onSelect={props.onReportProblemButtonPress}
            label="Report a problem"
            icon={
              <MaterialIcons size={22} name="report-problem" color="orange" />
            }
          />

          <HorizontalActionButton
            onSelect={props.onLogoutButtonPress}
            label="Logout session"
            icon={
              <MaterialIcons
                size={22}
                name="logout"
                color={theme.colors.secondary as unknown as string}
              />
            }
          />
        </VStack>
      </VStack>
    </VStack>
  );
};
