import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import {
  ParticipantProfileWithPropertiesDto,
  SanitizedUserDto,
} from "../services/api-service-sub-services/users-api-service";
import { logoutAction } from "./app.slice";

const USER_SLICE_NAME = "user";

type Account = Omit<SanitizedUserDto, "updatedAt" | "roles">;

export type State = {
  accessToken: string;
  refreshToken: string;
  isAuthenticated: boolean;
  account: Partial<Account> & {
    profile?: ParticipantProfileWithPropertiesDto;
  };
};

const initialState: State = {
  accessToken: "",
  refreshToken: "",
  isAuthenticated: false,
  account: {
    _id: "",
  },
};

export const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState: initialState,
  reducers: {
    botAccountBlocked(
      state: State,
      action: PayloadAction<{ blockedProfileId: string }>
    ) {
      state.account.profile?.blockedProfiles?.push(
        action.payload.blockedProfileId
      );
    },
    userProfileExitScheduleUpdated(
      state: State,
      action: PayloadAction<ParticipantProfileWithPropertiesDto>
    ) {
      if (state.account.profile) {
        state.account.profile.enteredNewsfeedAt =
          action.payload.enteredNewsfeedAt;
      } else {
        throw new Error("Fetch the user profile first before updating it!");
      }
    },
    userProfileFetched(
      state: State,
      action: PayloadAction<ParticipantProfileWithPropertiesDto>
    ) {
      state.account.profile = action.payload;
    },
    userAuthenticated(
      state: State,
      action: PayloadAction<{
        accessToken: string;
        refreshToken: string;
        user: Account;
      }>
    ) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isAuthenticated = true;
      state.account = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction, (state) => {
      state = initialState;
      return state;
    });
  },
});

// Define selectors alongside reducers:
// See: https://redux.js.org/usage/deriving-data-selectors#define-selectors-alongside-reducers

export const selectUserIsAuthenticated = createSelector(
  (state: RootState) => state.user.isAuthenticated,
  (isAuthenticated) => isAuthenticated
);

export const selectUserId = createSelector(
  (state: RootState) => state.user.account._id,
  (userId) => userId
);

export const selectUserStudyId = createSelector(
  (state: RootState) => state.user.account.currentStudy,
  (currentStudy) => currentStudy
);

export const selectUserProfile = createSelector(
  (state: RootState) => state.user.account.profile,
  (profile) => profile
);

export const selectBlockedBotProfiles = createSelector(
  (state: RootState) => state.user.account.profile?.blockedProfiles || [],
  (blockedProfiles) => blockedProfiles
);

export const selectProfileRelativeTimeBase = createSelector(
  (state: RootState) => state.user.account.profile,
  (profile) => profile?.enteredNewsfeedAt
);
