import { VStack } from "native-base";
import React from "react";
import {
  CommentReturnDto,
  ParticipantProfileWithPropertiesDto,
} from "../../../../services/api-service-sub-services/studies-api-service";
import dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";
import { CommentThreadItem } from "./comment-thread-item.component";
import { OpenCreateNewCommentSheetParams } from "../../hooks/use-open-comment-bottom-sheet.hook";
import { OnCommentInteractionButtonPressArgs } from "../../post.container";

dayjs.extend(relativeTime);

type Props = {
  profile: ParticipantProfileWithPropertiesDto;
  childComments: CommentReturnDto[];
  onCreateCommentReply?: (
    params: OpenCreateNewCommentSheetParams
  ) => Promise<void>;
  onCommentInteractionButtonPress: (
    args: OnCommentInteractionButtonPressArgs
  ) => Promise<void>;
  disabled?: boolean;
};

export const ThreadedComments = (props: Props) => {
  const handleCreateCommentReply = (args: {
    content?: string;
    profile?: ParticipantProfileWithPropertiesDto;
  }) => {
    props.onCreateCommentReply?.(args);
  };

  // do not render threaded comments if threaded comments modifier is disabled on the study settings
  if (props.disabled) {
    return null;
  }

  const renderComment = (
    comment: CommentReturnDto,
    level = 1,
    hideLineBar: boolean = false
  ) => {
    return (
      <CommentThreadItem
        onCommentInteractionButtonPress={props.onCommentInteractionButtonPress}
        comment={comment}
        onCreateCommentReply={handleCreateCommentReply}
        level={level}
        hideLineBar={hideLineBar}
        hideReplyButton={level >= 1}
      >
        {comment.children?.map((childComment, index) => {
          return renderComment(
            childComment,
            level + 1,
            index === (comment.children?.length || 0) - 1
          );
        })}
      </CommentThreadItem>
    );
  };

  return (
    <VStack>
      {props.childComments.map((comment, index) => {
        return (
          <VStack key={comment._id}>
            {renderComment(
              comment,
              0,
              props.childComments.length - 1 === index
            )}
          </VStack>
        );
      })}
    </VStack>
  );
};
