import { Box, IconButton, IIconButtonProps, Spinner } from "native-base";
import React from "react";

export type CircularButtonProps = {
  isLoading?: boolean;
} & IIconButtonProps;

export const CircularButton = (props: CircularButtonProps) => {
  return (
    <Box
      borderRadius="xl"
      backgroundColor={
        props.isDisabled || props.isLoading ? "medGrey" : "xLightGrey"
      }
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      {...(props.isLoading && {
        padding: "11px",
      })}
    >
      {props.isLoading ? (
        <Spinner size="sm" color="black" />
      ) : (
        <IconButton
          {...props}
          _pressed={{
            backgroundColor: "primary.100:alpha.20",
          }}
          alignItems="center"
          justifyContent="center"
        />
      )}
    </Box>
  );
};
