import { NavigationContainer } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import React, { Fragment } from "react";
import { AuthStackNavigator } from "./navigators/auth-stack.navigator";
import { useAppSelector } from "../store/store";
import { selectUserIsAuthenticated } from "../store/user.slice";
import { AppStackNavigator } from "./navigators/app-stack.navigator";
import * as Linking from "expo-linking";
import { Box, Text } from "native-base";
import { PostHogProvider } from "posthog-react-native";
import Constants from "expo-constants";
import { SheetProvider } from "react-native-actions-sheet";
import "../modules/core/sheets/sheets";
import { TimeBasedPopupsProvider } from "../modules/core/context/time-based-popups.context";
import { modalStack } from "../modules/core/modals/modal-stack";
import { ModalProvider } from "react-native-modalfy";
import { Platform } from "react-native";

const prefix = Linking.createURL("/");

export const NavContainer = () => {
  const linking = {
    prefixes: [prefix],
  };

  const isAuthenticated = useAppSelector(selectUserIsAuthenticated);

  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <PostHogProvider
        apiKey={Constants.expoConfig?.extra?.posthogApiKey}
        options={{
          host: "https://app.posthog.com",
        }}
      >
        <ModalProvider stack={modalStack}>
          <SheetProvider>
            {Platform.OS === "web" ? (
              <Box
                display="flex"
                height="100%"
                width="100%"
                backgroundColor="black"
              >
                <Box
                  display="flex"
                  height="100%"
                  width="100%"
                  maxWidth="625px"
                  alignSelf="center"
                >
                  {isAuthenticated ? (
                    <TimeBasedPopupsProvider>
                      <AppStackNavigator />
                    </TimeBasedPopupsProvider>
                  ) : (
                    <AuthStackNavigator />
                  )}
                  <StatusBar style="auto" />
                </Box>
              </Box>
            ) : (
              <Fragment>
                {isAuthenticated ? (
                  <TimeBasedPopupsProvider>
                    <AppStackNavigator />
                  </TimeBasedPopupsProvider>
                ) : (
                  <AuthStackNavigator />
                )}
                <StatusBar style="auto" />
              </Fragment>
            )}
          </SheetProvider>
        </ModalProvider>
      </PostHogProvider>
    </NavigationContainer>
  );
};
