import {
  Box,
  Button,
  CloseIcon,
  HStack,
  IconButton,
  ScrollView,
  Text,
  useTheme,
  VStack,
} from "native-base";
import React, { useEffect, useMemo, useState } from "react";
import { ModalComponentProp } from "react-native-modalfy";
import { ModalStackParams } from "./modal-stack";
import { Dimensions, Platform } from "react-native";
import WebView from "react-native-webview";
import RenderHTML from "react-native-render-html";
import Constants from "expo-constants";
import IframeRenderer, { iframeModel } from "@native-html/iframe-plugin";
import BouncyCheckbox from "react-native-bouncy-checkbox/build/dist/BouncyCheckbox";
import { Routes } from "../../../navigation/routes";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { AppStackParamList } from "../../../navigation/navigators/app-stack.navigator";

type Props = ModalComponentProp<ModalStackParams, void, "InstructionsModal">;
export const InstructionsModal = (props: Props) => {
  const navigation = useNavigation<NavigationProp<AppStackParamList>>();
  const { width } = Dimensions.get("window");
  const theme = useTheme();
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const filterContent = (content: string) => {
    let result = content;
    result = result.replace(/<p><br><\/p>/g, "");
    return result;
  };

  const htmlContent = useMemo(() => {
    return filterContent(props.modal.params!.instructions);
  }, [props.modal.params]);

  const { continueActionType, continueActionMeta } = props.modal.params ?? {};

  const isCheckboxRequired = continueActionMeta?.isCheckboxRequired || false;

  // set the default value of the [contentType] to HTML
  const contentType = props.modal.params?.type ?? "html";

  const handleClose = () => {
    props.modal.closeModal("InstructionsModal", props.modal.params?.onClose);
  };

  // close the modal automatically after the timer expires
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (continueActionType === "time") {
      const additionalSeconds = continueActionMeta?.time || 5;
      timer = setTimeout(() => {
        handleClose();
      }, additionalSeconds * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [continueActionType, continueActionMeta]);

  const handleLinkPress = (url: string) => {
    navigation.navigate(Routes.APP_STACK__IN_APP_BROWSER, {
      url: url,
    });
  };

  const modalWidth = useMemo(() => width * 0.8, []);

  return (
    <VStack
      variant="screen"
      borderRadius="xl"
      p={5}
      space="5"
      width={width * 0.95}
      maxWidth={Platform.OS === "web" ? "625px" : width}
    >
      {/*only display the x icon once there is no configured continue button type*/}
      {!continueActionType && (
        <Box position="absolute" top="1" right="1" zIndex={10}>
          <IconButton
            icon={<CloseIcon color="medGrey" />}
            onPress={handleClose}
          />
        </Box>
      )}

      <VStack space="1">
        {contentType === "qualtrics" && (
          <Box height="600px" width="100%">
            <WebView
              incognito={true}
              source={{
                uri: `https://duke.qualtrics.com/jfe/form/${
                  props.modal.params!.instructions
                }`,
              }}
              style={{ height: 100, width: "100%" }}
              androidLayerType="software"
            />
          </Box>
        )}

        {contentType === "html" && (
          <ScrollView
            maxHeight="600px"
            width="100%"
            showsVerticalScrollIndicator={false}
          >
            <RenderHTML
              classesStyles={{
                "ql-align-right": {
                  textAlign: "right",
                },
                "ql-align-center": {
                  textAlign: "center",
                },
                "ql-align-justify": {
                  textAlign: "justify",
                },
                "ql-size-small": {
                  fontSize: 12,
                },
                "ql-size-large": {
                  fontSize: 18,
                },
                "ql-size-huge": {
                  fontSize: 24,
                },
                "ql-size-normal": {
                  fontSize: 14,
                },
              }}
              WebView={WebView}
              contentWidth={width * 0.95 - 10}
              source={{
                html: htmlContent,
              }}
              baseStyle={{
                color: theme.colors.black as unknown as string,
              }}
              ignoredDomTags={["br"]}
              enableCSSInlineProcessing={true}
              enableExperimentalBRCollapsing={true}
              enableExperimentalGhostLinesPrevention={true}
              systemFonts={[...Constants.systemFonts]}
              tagsStyles={{
                p: {
                  marginBottom: 8,
                  marginTop: 8,
                  fontSize: 14,
                },
                span: {
                  fontSize: 14,
                },
                img: {
                  // @ts-expect-error - properly define the theme provider
                  backgroundColor: theme.colors.darkGrey,
                  objectFit: "contain",
                  borderRadius: 8,
                  overflow: "hidden",
                },
              }}
              renderers={{
                iframe: IframeRenderer,
              }}
              customHTMLElementModels={{
                iframe: iframeModel,
              }}
              renderersProps={{
                img: {
                  enableExperimentalPercentWidth: true,
                  initialDimensions: {
                    height: 100,
                    width: modalWidth,
                  },
                },
                iframe: {
                  scalesPageToFit: true,
                  webViewProps: {},
                },
                a: {
                  onPress: (event, href) => {
                    handleLinkPress(href);
                  },
                },
              }}
            />
          </ScrollView>
        )}
      </VStack>

      {continueActionType === "button" && (
        <VStack alignItems="flex-end">
          <Button
            variant="unstyled"
            size="sm"
            onPress={handleClose}
            backgroundColor="xLightGrey"
            borderRadius="xl"
          >
            <Text variant="button" bold>
              {continueActionMeta?.buttonLabel || "Continue"}
            </Text>
          </Button>
        </VStack>
      )}

      {continueActionType === "checkbox" && (
        <HStack
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          backgroundColor="xLightGrey"
          p={3}
          minHeight="65px"
          borderRadius="xl"
        >
          <BouncyCheckbox
            textComponent={
              <Text variant="button" ml={1} fontWeight="600" color="text-black">
                {continueActionMeta?.checkboxLabel || "I understand"}
                <Text variant="button" color="text-black">
                  {isCheckboxRequired ? "*" : ""}
                </Text>
              </Text>
            }
            fillColor={theme.colors.primary as unknown as string}
            isChecked={isConsentChecked}
            useBuiltInState={false}
            size={18}
            onPress={(isChecked) => setIsConsentChecked(!isChecked)}
          />
          {(!isCheckboxRequired ||
            (isCheckboxRequired && isConsentChecked)) && (
            <Button variant="unstyled" size="sm" onPress={handleClose}>
              <Text variant="button" bold>
                {continueActionMeta?.buttonLabel || "Continue"}
              </Text>
            </Button>
          )}
        </HStack>
      )}
    </VStack>
  );
};
