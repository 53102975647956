import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Routes } from "../routes";
import { FeedContainer } from "../../modules/feed/feed.container";
import { FeedScreen } from "../../modules/feed/feed.screen";

export type HomeStackParamList = {
  [Routes.APP_STACK__MAIN__FEED__HOME]: {};
};

const Feed = FeedContainer(FeedScreen);
const Stack = createStackNavigator<HomeStackParamList>();

export const HomeStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name={Routes.APP_STACK__MAIN__FEED__HOME}
        component={Feed}
      />
    </Stack.Navigator>
  );
};
