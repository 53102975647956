import {
  ParticipantProfileWithPropertiesDto,
  useStudiesControllerCreateCommentForPostMutation,
} from "../../../services/api-service-sub-services/studies-api-service";
import { SheetManager } from "react-native-actions-sheet";
import { useAppSelector } from "../../../store/store";
import {
  selectUserProfile,
  selectUserStudyId,
} from "../../../store/user.slice";
import { useTrackEvent } from "../../core/hooks/use-track-event";
import { extractControlledMentions } from "../utils/mentions";
import { selectSimulatedProfiles } from "../../../store/app.slice";
import dayjs from "dayjs";
import { logger } from "../../core/utils/logger.util";
import { useReactivePopupsSideEffect } from "../../core/hooks/use-reactive-popups-side-effect";
import { events } from "../../../constants/analytics.constants";

export type UseOpenCommentBottomSheetProps = {
  postId: string;
  onSuccessCallback?: () => Promise<void> | void;
};

export type OpenCreateNewCommentSheetParams = {
  profile?: ParticipantProfileWithPropertiesDto;
  content?: string;
  parentCommentId?: string;
};

/**
 * Hook to open the create new comment bottom sheet. This can only be used
 * inside containers (smart components).
 * @param props
 */
export const useOpenCommentBottomSheet = (
  props: UseOpenCommentBottomSheetProps
) => {
  const studyId = useAppSelector(selectUserStudyId);
  const userProfile = useAppSelector(selectUserProfile);
  const track = useTrackEvent();
  const simulatedProfiles = useAppSelector(selectSimulatedProfiles);
  const { triggerPopupSideEffect } = useReactivePopupsSideEffect();

  // mutation for creating new comments
  const [postNewComment] = useStudiesControllerCreateCommentForPostMutation({
    selectFromResult: ({}) => ({}),
  });

  const openCreateNewCommentSheet = async (
    params: OpenCreateNewCommentSheetParams
  ) => {
    await SheetManager.show("create-new-comment-sheet", {
      payload: {
        profile: params.profile,
        content: params.content,
        onCommentButtonPress: async (content: string) => {
          try {
            if (!studyId || !userProfile) {
              return;
            }

            const { taggedProfiles, contentWithOrWithoutMentions } =
              // @ts-ignore
              extractControlledMentions(content, simulatedProfiles);

            await postNewComment({
              id: studyId,
              postId: props.postId,
              createCommentDto: {
                content: contentWithOrWithoutMentions,
                date: dayjs().toISOString(),
                profile: userProfile._id,
                mediaUrls: [],
                taggedProfiles: taggedProfiles,
                parentId: params.parentCommentId,
              },
            }).unwrap();
            track(events.userCreatedComment);
            await props?.onSuccessCallback?.();
            await triggerPopupSideEffect("when-user-creates-comment");
          } catch (e) {
            track(events.userUnableToCreateComment);
            logger.error("Failed to create comment", e);
          }
        },
        onCancelButtonPress: async (content: string) => {
          track(events.userCancelledCreatingComment, { content: content });
        },
      },
    });
  };

  return [openCreateNewCommentSheet];
};
