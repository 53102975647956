import React from "react";
import { Button, HStack, Text } from "native-base";

export const SmallActionButton = (props: {
  counterValue: number | string;
  icon: React.ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
  onPress?: () => void;
}) => {
  return (
    <Button
      disabled={!!props.isDisabled}
      onPress={() => {
        props.onPress?.();
      }}
      variant="reaction"
      backgroundColor={props.isActive ? "medGrey" : "transparent"}
      outlineColor="medGrey"
      padding={0}
      borderRadius="xl"
      _pressed={{ backgroundColor: "medGrey" }}
    >
      <HStack alignItems="center" space={1}>
        {props.icon}
        <Text fontSize={14} color="black" fontWeight="600">
          {props.counterValue}
        </Text>
      </HStack>
    </Button>
  );
};
