import {
  extractFullName,
  extractProfileProperty,
} from "../../profile/utils/profile.util";
import { useTreatmentArmExperience } from "../../core/hooks/use-treatment-arm-experience";
import { ParticipantProfileValue } from "../../../services/api-service-sub-services/users-api-service";

export const useGetDisplayName = () => {
  const treatmentArmExperience = useTreatmentArmExperience();

  return (args: { participantProfileValues: ParticipantProfileValue[] }) => {
    const { participantProfileValues } = args;
    let displayName = extractFullName(participantProfileValues);
    if (
      treatmentArmExperience.profile.isFirstNameEnabled &&
      !treatmentArmExperience.profile.isLastNameEnabled
    ) {
      displayName =
        extractProfileProperty("first-name", participantProfileValues) || "";
    } else if (
      !treatmentArmExperience.profile.isFirstNameEnabled &&
      treatmentArmExperience.profile.isLastNameEnabled
    ) {
      displayName =
        extractProfileProperty("last-name", participantProfileValues) || "";
    } else if (
      !treatmentArmExperience.profile.isFirstNameEnabled &&
      !treatmentArmExperience.profile.isLastNameEnabled
    ) {
      // display anonymous user
      displayName = extractFullName();
    }

    return displayName;
  };
};
