import { App } from "./src/app";
import { LogBox } from "react-native";
import * as Sentry from "sentry-expo";

Sentry.init({
  dsn: "https://00fcc25827406a2b3ec4e326b98bf912@o4505640284323840.ingest.sentry.io/4505640286224384",
  enableInExpoDevelopment: false,
  tracesSampleRate: 0.3,
});

LogBox.ignoreLogs([
  "In React 18, SSRProvider is not necessary and is a noop. You can remove it from your app.",
  "Constants.platform.ios.model has been deprecated in favor of expo-device's Device.modelName property. This API will be removed in SDK 45.",
]);
export default App;
