import { extractMentions, MENTION_REGEX } from "../../utils/mentions";
import { Text } from "native-base";
import React from "react";
import { TaggedProfilesEntity } from "../../../../services/api-service-sub-services/studies-api-service";

export type TextContentRendererProps = {
  content: string;
  mentionedProfiles?: TaggedProfilesEntity[];
  onMentionedProfilePress: (profileId: string) => void;
  truncate?: boolean;
};

/**
 * Renders text content with mentions. This only accepts the content and mentioned profiles
 *
 * @param {TextContentRendererProps} props
 */
export const TextContentRenderer = (props: TextContentRendererProps) => {
  const contentFragments = extractMentions(props.content);

  return (
    <Text lineHeight={21} numberOfLines={!!props.truncate ? 3 : undefined}>
      {contentFragments.map((fragment: string, index: number) => {
        if (MENTION_REGEX.test(fragment)) {
          const mention = fragment.replace(/(\[)([^\]]+)(])/g, "$2");
          const mentionedProfile = props.mentionedProfiles?.find(
            (profile) => profile.matcher === fragment
          );

          if (mentionedProfile) {
            return (
              <Text
                fontSize="16"
                key={index}
                color="secondary"
                onPress={() => {
                  props.onMentionedProfilePress(mentionedProfile.profileId);
                }}
              >
                {mention}
              </Text>
            );
          } else {
            return (
              <Text fontSize="16" key={index}>
                {mention}
              </Text>
            );
          }
        }
        return (
          <Text fontSize="16" key={index}>
            {fragment}
          </Text>
        );
      })}
    </Text>
  );
};
