import { Platform, TouchableOpacity } from "react-native";
import { OptimizedCdnImage } from "../../../core/components/optimzed-cdn-image";
import { AspectRatio, Image } from "native-base";
import React from "react";

export type PostContentImageProps = {
  onPress?: () => void | Promise<void>;
  imageUri: string;
};

export const PostContentImage = (props: PostContentImageProps) => {
  return (
    <TouchableOpacity
      onPress={async () => {
        props.onPress?.();
      }}
    >
      <OptimizedCdnImage
        uri={props.imageUri}
        width={1080}
        render={(source) =>
          Platform.OS === "web" ? (
            <Image
              src={source.uri}
              alt="post-image"
              resizeMethod="scale"
              resizeMode="cover"
              height="100%"
              width="100%"
              style={{ aspectRatio: 1 }}
            />
          ) : (
            <AspectRatio
              alignSelf="center"
              borderRadius="8"
              overflow="hidden"
              width="100%"
              mx="5"
              ratio={1}
            >
              <Image
                src={source.uri}
                alt="post-image"
                resizeMethod="scale"
                resizeMode="cover"
                height="100%"
                width="100%"
              />
            </AspectRatio>
          )
        }
      />
    </TouchableOpacity>
  );
};
