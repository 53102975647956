import { usePostHog } from "posthog-react-native";
import { useAppSelector } from "../../../store/store";
import {
  selectUserIsAuthenticated,
  selectUserProfile,
} from "../../../store/user.slice";
import { ParticipantProfileWithPropertiesDto } from "../../../services/api-service-sub-services/users-api-service";
import dayjs from "dayjs";
import { logger } from "../utils/logger.util";

export const useTrackEvent = () => {
  const posthog = usePostHog();
  const isAuthenticated = useAppSelector(selectUserIsAuthenticated);
  const profile = useAppSelector(selectUserProfile);

  return (
    event: string,
    data?: { [key: string]: string | number | boolean },
    identify = false
  ) => {
    const metadata: Partial<ParticipantProfileWithPropertiesDto> & {
      [key: string]: string | number | boolean;
    } = {};

    if (identify && !!data?.participantCode) {
      posthog?.identify(data?.participantCode as string);
    }

    if (isAuthenticated) {
      metadata.treatmentArm = profile?.treatmentArm;
      metadata.study = profile?.study;
      metadata.participantCode = profile?.participantCode?.toLowerCase();
      metadata.timestamp = dayjs().utc().toISOString();
    }

    // copy values from additionalData to metadata
    if (data) {
      Object.keys(data).forEach((key) => {
        metadata[key] = data[key];
      });
    }

    logger.info("Event tracked", { event: event, ...metadata });

    // TODO - dispatch to the analytics engine

    // always delete the `content` key from metadata before sending to posthog
    delete metadata.content;
    posthog?.capture(event, metadata);
  };
};
