import { consoleTransport, logger as rnLogger } from "react-native-logs";

export const logger = rnLogger.createLogger({
  transport: consoleTransport,
  transportOptions: {
    colors: {
      info: "blueBright",
      warn: "yellowBright",
      error: "redBright",
    },
  },
  dateFormat: "time",
  printLevel: true,
  printDate: true,
});
