import { Image, Text, View } from "native-base";
import React from "react";
import { ParticipantProfileWithPropertiesDto } from "../../../services/api-service-sub-services/studies-api-service";
import { useProfileValues } from "../../profile/hooks/use-profile-values";
import { OptimizedCdnImage } from "./optimzed-cdn-image";

export type ProfileAvatarProps = {
  profile?: ParticipantProfileWithPropertiesDto;
  length?: number | string;
  small?: boolean;
};
export const ProfileAvatar = (props: ProfileAvatarProps) => {
  const profileValues = useProfileValues(props.profile?.properties);

  return (
    <View
      shadow="3"
      backgroundColor="xLightGrey"
      borderRadius="full"
      height={props.length || "16"}
      width={props.length || "16"}
    >
      <View
        justifyContent="center"
        alignItems="center"
        borderRadius="full"
        borderColor="primary"
        borderWidth="1"
        overflow="hidden"
        flex="1"
      >
        {profileValues.profilePicture && (
          <OptimizedCdnImage
            uri={profileValues.profilePicture}
            width={256}
            render={(source) => (
              <Image
                resizeMode="cover"
                alt="avatar"
                overflow="hidden"
                height="100%"
                width="100%"
                source={source}
              />
            )}
          />
        )}
        {!profileValues.profilePicture && (
          <Text
            color="primary"
            variant="heading"
            fontSize={props.small ? "sm" : undefined}
          >
            {profileValues.nameInitials}
          </Text>
        )}
      </View>
    </View>
  );
};
