import { Platform, TouchableOpacity } from "react-native";
import { AspectRatio, HStack, Image, Text, View } from "native-base";
import { ProfileAvatar } from "../../../core/components/profile-avatar";
import React from "react";
import { ParticipantProfileWithPropertiesDto } from "../../../../services/api-service-sub-services/users-api-service";
import { PlaneIcon } from "../../../core/icons/plane-icon";
import { useAppSelector } from "../../../../store/store";
import {
  selectPostPromptPlaceholderText,
  selectPostSubmitIconUrl,
} from "../../../../store/app.slice";

type NewPostPromptSmallProps = {
  onPress: () => Promise<void> | void;
  profile?: ParticipantProfileWithPropertiesDto;
};

export const NewPostPromptSmall = (props: NewPostPromptSmallProps) => {
  const promptPlaceholderText = useAppSelector(selectPostPromptPlaceholderText);
  const postSubmitIconUrl = useAppSelector(selectPostSubmitIconUrl);

  return (
    <View bg="white" p={3} shadow="1" borderRadius="xl">
      <TouchableOpacity
        onPress={props.onPress}
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "white",
        }}
      >
        {Platform.OS === "web" ? (
          <View style={{ aspectRatio: 1 }}>
            <ProfileAvatar profile={props.profile} length="10" />
          </View>
        ) : (
          <AspectRatio ratio={1} height="9">
            <ProfileAvatar profile={props.profile} length="10" />
          </AspectRatio>
        )}
        <HStack
          ml="3"
          pt="1"
          flex="1"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color="darkGrey" variant="subtitle1">
            {promptPlaceholderText}
          </Text>
          {postSubmitIconUrl ? (
            <Image
              height="24px"
              width="24px"
              resizeMode="contain"
              source={{ uri: postSubmitIconUrl }}
              alt="send"
            />
          ) : (
            <PlaneIcon />
          )}
        </HStack>
      </TouchableOpacity>
    </View>
  );
};
