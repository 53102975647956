import { ProfileAvatar } from "../../../core/components/profile-avatar";
import { HStack, Text } from "native-base";
import React from "react";
import { ParticipantProfileWithPropertiesDto } from "../../../../services/api-service-sub-services/studies-api-service";
import { useGetDisplayName } from "../../utils/names";
import { formatDateTimeRelative } from "../../../core/utils/date.util";

export type CommentThreadItemHeaderProps = {
  date: string | Date;
  profile: ParticipantProfileWithPropertiesDto;
  hideAvatar?: boolean;
};

/**
 * Displays the top header of a comment in a thread
 *
 * @param {CommentThreadItemHeaderProps} props - the props of the component
 * @return {JSX.Element} the component
 */
export const CommentThreadItemHeader = (
  props: CommentThreadItemHeaderProps
): JSX.Element => {
  const getDisplayName = useGetDisplayName();

  const name = getDisplayName({
    participantProfileValues: props.profile.properties,
  });

  return (
    <HStack space="2" alignItems="center">
      {!props.hideAvatar && (
        <ProfileAvatar profile={props.profile} length="8" />
      )}
      <HStack alignItems="center" space={1}>
        <Text bold fontSize="14">
          {name}
        </Text>
        <Text fontSize="12" color="medGrey">
          {formatDateTimeRelative(props.date)}
        </Text>
      </HStack>
    </HStack>
  );
};
