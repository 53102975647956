import { extendTheme } from "native-base";
import { RFValue } from "react-native-responsive-fontsize";

const BASE_DESIGN_SCREEN_HEIGHT = 750;

export const defaultTheme = extendTheme({
  fontConfig: {
    NunitoSans: {
      200: {
        normal: "NunitoSans_200ExtraLight",
      },
      300: {
        normal: "NunitoSans_300Light",
      },
      400: {
        normal: "NunitoSans_400Regular",
      },
      600: {
        normal: "NunitoSans_600SemiBold",
      },
      700: {
        normal: "NunitoSans_700Bold",
      },
      800: {
        normal: "NunitoSans_800ExtraBold",
      },
      900: {
        normal: "NunitoSans_900Black",
      },
    },
  },
  fonts: {
    heading: "NunitoSans",
    body: "NunitoSans",
  },
  colors: {
    primary: "#2C0A60",
    primaries: {
      "50": "#f2e7ff",
      "100": "#d2bafa",
      "200": "#b48cf3",
      "300": "#975ded",
      "400": "#7930e7",
      "500": "#5f18ce",
      "600": "#4a12a1",
      "700": "#350c73",
      "800": "#200647",
      "900": "#0d001c",
    },
    secondary: "#FF5146",
    lightGrey: "#E6E9F2",
    xLightGrey: "#F4F6FA",
    darkGrey: "#83899B",
    black: "#1F2330",
    "text-black": "#444A5F",
    medGrey: "#B8BBC6",
  },
  components: {
    Select: {
      baseStyle: {
        borderWidth: 0,
        paddingLeft: "5",
        fontSize: 16,
        placeholderTextColor: "medGrey",
      },
    },
    VStack: {
      variants: {
        screen: {
          backgroundColor: "white",
        },
        "scrollable-screen": {
          backgroundColor: "white",
          display: "flex",
          flex: 1,
        },
      },
    },
    Input: {
      defaultProps: {
        height: 70,
      },
      baseStyle: {
        backgroundColor: "xLightGrey",
        borderRadius: 13,
        color: "#1F2330",
        placeholderTextColor: "#1F2330",
        borderWidth: 0,
        fontSize: 22,
      },
      variants: {
        primary: {
          backgroundColor: "xLightGrey",
          borderRadius: 13,
          color: "#1F2330",
          placeholderTextColor: "medGrey",
          paddingLeft: 5,
        },
      },
    },
    View: {
      variants: {
        screen: {
          px: "5",
          py: "10",
          flex: 1,
        },
      },
    },
    Button: {
      defaultProps: {
        variant: "primary",
        _pressed: {
          opacity: 0.5,
        },
      },
      variants: {
        primary: {
          width: "100%",
          backgroundColor: "primary",
          borderRadius: 100,
          height: 16,
          _text: {
            color: "white",
            fontWeight: 700,
            fontSize: "16",
          },
        },
        secondary: {
          width: "100%",
          backgroundColor: "secondary",
          borderRadius: 100,
          height: 16,
          _text: {
            color: "white",
            fontWeight: 700,
            fontSize: "16",
          },
        },
        actionButton: {
          borderRadius: "xl",
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          _text: {
            color: "text-black",
            fontWeight: 700,
            fontSize: "16",
          },
          _pressed: {
            backgroundColor: "medGrey",
          },
        },
        reaction: () => ({
          borderRadius: 100,
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
          _text: {
            color: "white",
            fontWeight: 700,
            fontSize: "16",
          },
        }),
      },
    },
    Heading: {
      baseStyle: {
        color: "text",
        fontSize: RFValue(35, BASE_DESIGN_SCREEN_HEIGHT),
      },
      variants: {
        title1: {
          fontWeight: 700,
          fontSize: RFValue(35, BASE_DESIGN_SCREEN_HEIGHT),
        },
      },
    },
    TextArea: {
      baseStyle: {
        color: "text-black",
        fontSize: 16,
      },
    },
    Text: {
      baseStyle: {
        color: "text-black",
        fontWeight: 400,
        fontSize: 16,
      },
      variants: {
        // Material design tokens for typography
        heading1: {
          fontWeight: 300,
          fontSize: 96,
        },
        heading2: {
          fontWeight: 300,
          fontSize: 60,
        },
        heading3: {
          fontWeight: 400,
          fontSize: 48,
        },
        heading4: {
          fontWeight: 400,
          fontSize: 34,
        },
        heading5: {
          fontWeight: 400,
          fontSize: 24,
        },
        heading6: {
          fontWeight: 500,
          fontSize: 20,
        },
        subtitle1: {
          fontWeight: 400,
          fontSize: 16,
        },
        subtitle2: {
          fontWeight: 500,
          fontSize: 14,
        },
        body1: {
          fontWeight: 400,
          fontSize: 16,
        },
        body2: {
          fontWeight: 400,
          fontSize: 14,
        },
        button: {
          color: "text-black",
          fontWeight: 500,
          fontSize: 14,
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
        caption: {
          fontSize: 12,
          fontWeight: 400,
        },
        overline: {
          fontSize: 10,
          fontWeight: 400,
        },
        // Custom design tokens by our designer (obsolete)
        title: {
          fontWeight: 700,
          fontSize: RFValue(35, BASE_DESIGN_SCREEN_HEIGHT),
        },
        heading: {
          fontWeight: 700,
          fontSize: RFValue(18, BASE_DESIGN_SCREEN_HEIGHT),
        },
        small: {
          fontSize: RFValue(14, BASE_DESIGN_SCREEN_HEIGHT),
        },
        label: {
          fontSize: RFValue(12, BASE_DESIGN_SCREEN_HEIGHT),
        },
      },
    },
  },
});
